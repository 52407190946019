@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@user-guidance-prefix: ~'@{cor-prefix}-user-guidance';

.@{user-guidance-prefix} {
  min-height: var(--doc-height);
  padding: 40px 48px;
  max-width: 1216px;
  display: flex;
  flex-direction: column;
  row-gap: 48px;
  overflow: scroll;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-box1 {
      width: 739px;
      height: 280px;
    }

    &-box2 {
      width: 357px;
      height: 280px;
    }

    &-box3 {
      width: 357px;
      height: 280px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    transition: transform 0.3s ease;
    border: 1px solid var(--p1, #000656);
    border-radius: 32px;
    cursor: pointer;
    background-image: url(../assets/bg_welcome.png),
      linear-gradient(239deg, rgba(0, 128, 255, 0.1) 0%, rgba(0, 128, 255, 0) 99.94%);
    background-repeat: no-repeat;
    background-size: 300px 200px, cover;
    background-position: 100% 0;
    .item-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg:first-of-type {
        path {
          fill: rgb(0, 128, 255);
        }
      }
    }

    .item-msg {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      .item-msg-1 {
        color: var(--text-1, #000656);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }

      .item-msg-2 {
        color: var(--text-1, #333);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        transition: all 0.3s ease; /* 添加过渡效果 */
      }

      .item-msg-3 {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          color: var(--p1, #000656);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }

    &:hover {
      transform: scale(0.99);
      .item-msg-2 {
        -webkit-line-clamp: unset; /* 移除行数限制 */
      }
    }

    &.active {
      border: 1px solid #e0e2eb;
      background-image: url(../assets/bg_welcome1.png),
        linear-gradient(239deg, rgba(238, 240, 246, 0.4) 0%, rgba(238, 240, 246, 0) 100%);
      background-repeat: no-repeat;
      background-size: 300px 200px, cover;
      background-position: 100% 0;

      .item-msg-1 {
        color: var(--text-1, #333);
      }

      .item-msg-2 {
        color: var(--text-2, rgba(51, 51, 51, 0.6));
      }

      .item-msg-3-connect {
        span {
          color: var(--text-2, rgba(51, 51, 51, 0.6));
        }
      }

      .item-top {
        svg:first-of-type {
          path {
            fill: rgb(51, 51, 51);
          }
        }
      }
    }
  }

  &-title {
    &-1 {
      display: flex;
      align-items: center;

      span:nth-of-type(1) {
        color: var(--text-1, #333);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }

      span:nth-of-type(2) {
        margin-left: 16px;
        color: var(--p1, #000656);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        border-radius: 12px;
        background: rgba(0, 128, 255, 0.1);
        padding: 8px 12px;
      }
    }

    &-2 {
      margin-top: 16px;
      color: var(--text-2, rgba(51, 51, 51, 0.6));
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;

      span {
        color: var(--text-1, #333);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;