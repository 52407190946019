@import '~@/themes/func.less';
@mobileheader-prefix: ~'@{cor-prefix}-mobileheader';

.@{mobileheader-prefix} {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  &-btns {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }
  &-drawer {
    .ant-drawer-body {
      padding: 0 8px;
    }
    .ant-btn {
      font-family: Sora;
      font-size: 16px;
      font-weight: 700;
      width: 100%;
      line-height: 24px;
      padding: 8px !important;
      &:hover.ant-btn-text {
        background: none;
        color: #000656;
        font-weight: 600;
      }
    }
    .ant-btn.ant-btn-primary {
      padding: 8px;
      background-color: #000656;
      color: #ffffff;
      font-family: DM Serif Text;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      box-shadow: none;
      border: none;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;