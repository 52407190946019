
// Declare mixin
.font(@fontFamily: Arial, sans-serif; @fontWeight: normal; @fontStyle: normal) {
  font-family: @fontFamily;
  font-style: @fontStyle;
  font-weight: @fontWeight;
}


// .fontPinfang {
//   .font('PingFang SC', sans-serif, normal, normal)
// }
