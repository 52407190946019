.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: #e3efff;
      color: @text-color;
      border-radius: 8px;
      padding: 12px;
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: #e3efff;
    }
  }
}
