@import '~@/themes/func.less';
@mobileBooknow-prefix: ~'@{cor-prefix}-mobileBooknow';

.@{mobileBooknow-prefix} {
  padding: 24px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000656;
  &-left {
    width: 345px;
    position: relative;
    &-1 {
      position: absolute;
      bottom: -20px;
      left: -40px;
      width: 12px;
      height: 12px;
      background: var(--bright-pink, #fdb7e1);
    }
    &-2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: #fcfffc;
      .highlight1 {
        color: #febeff;
      }
      .highlight2 {
        color: #c7fff9;
      }
    }
    &-3 {
      margin-top: 16px;
      color: var(--Light-Grey, #f4f4f4);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }
  &-right {
    margin-top: 40px;
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    position: relative;
    border: 2px solid #c7fff9;
    .ant-btn-primary {
      display: flex;
      padding: 11px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      background: #ffffaf;
      color: var(--Dark, #000656);
      width: fit-content;
      margin: 0 auto;
      text-align: center;
      font-family: Sora;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.08px;
    }

    .ant-form-item-row {
      width: 100%;
      .ant-form-item-required {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;
        color: #fcfffc;
      }
      .ant-input {
        height: 42px;
      }
      &-sel {
        width: 100%;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 42px;
        border-radius: 6px;
        color: #000;
      }
    }

    &-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      color: #fcfffc;
    }
    .tips1 {
      height: 2px;
      width: 2px;
      background-color: #000656;
      position: absolute;
      top: -2px;
      left: -2px;
    }
    .tips2 {
      height: 4px;
      width: 4px;
      background-color: #c7fff9;
      position: absolute;
      top: -4px;
      right: -4px;
      div {
        height: 2px;
        width: 2px;
        background-color: #000656;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .tips3 {
      height: 4px;
      width: 4px;
      position: absolute;
      bottom: -2px;
      left: -2px;
      background-color: #000656;
      div {
        height: 2px;
        width: 2px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #c7fff9;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;