@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@sider-left-prefix: ~'@{cor-prefix}-tabbar';

.@{sider-left-prefix} {
  width: 100%;
  height: 80px;
  padding: 0px 32px;
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  background: var(--white, #ffffff);
  box-shadow: 0px -16px 32px 0px rgba(31, 44, 71, 0.05);

  &-item {
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    text-decoration: none;
    &-text {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      color: #333;
      opacity: 0.6;
    }
    &.active {
      .cor-tabbar-item-text {
        color: #000656;
        opacity: 1;
      }

      svg {
        path {
          fill: #000656;
          fill-opacity: 1;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;