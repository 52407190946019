@import '~@/themes/func.less';
@prvacy-prefix: ~'@{cor-prefix}-privacy';
.@{prvacy-prefix} {
  width: 1240px;
  margin: 0 auto;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: #222831;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  &-title {
    text-align: center;
  }
  h1,
  h2,
  h3,
  h4 {
    color: #222831;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 600;
    line-height: normal;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 24px;
  }
  ul {
    list-style: disc;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;