@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@mobilelanding-prefix: ~'@{cor-prefix}-mobilelanding';

.@{mobilelanding-prefix} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fcfffc;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  font-family: sora;
  &-center {
    width: 100%;
    margin: 0 auto;
    &-top {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
    b {
      font-weight: 600;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;