@import '~@/themes/func.less';
@plugin-sessions-prefix: ~'@{cor-prefix}-demos';
.@{plugin-sessions-prefix} {
  display: flex;
  height: var(--doc-height);
  align-items: stretch;
  &-left {
    border-right: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    width: 460px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-search {
      width: 600px;
      padding: 24px 24px 0 24px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f8f9fc;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #236fff;
      border-radius: 5px;
    }
  }

  &-right {
    flex: 1;
    display: flex;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;