@import '~@/themes/func.less';
@list-skeleton-prefix: ~'@{cor-prefix}-list-skeleton';
.@{list-skeleton-prefix} {
  display: flex;
  width: 100%;
  flex-direction: column;
  .ant-skeleton {
    margin: 0;
    width: 100%;
    .ant-skeleton-title {
      height: 88px;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;