@import '~@/themes/func.less';
@shop-connections-table-prefix: ~'@{cor-prefix}-shop-connections-table';
.@{shop-connections-table-prefix} {
  &-card {
    flex: 1;
    &.ant-card {
      border: none;
      .ant-card-head-title {
        display: flex;
        align-items: center;
        column-gap: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .ant-card-body {
        padding: 16px 0;
      }
      .ant-skeleton {
        padding: 24px;
      }
    }
  }
  &-items {
    &-item {
      display: flex;
      padding: 24px 32px;
      align-items: center;
      justify-content: space-between;
      &:not(:last-of-type) {
        border-bottom: 1px solid var(--border, #000656);
      }
      column-gap: 24px;
      &-name {
        flex: 1;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        column-gap: 8px;
        align-items: center;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;