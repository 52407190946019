@import '~@/themes/func.less';
@comment-list-prefix: ~'@{cor-prefix}-comment-list-card ';

.@{comment-list-prefix} {
  width: 100%;

  &-title {
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;


    span:nth-of-type(1) {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #333333;
    }

    span:nth-of-type(2) {

      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      color: #999999;
    }
  }

  &-comment {
    width: 100%;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    span {
      color: #999999;
      font-size: 12px;
    }
  }

  .ant-card-head-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-list-item {
    padding: 16px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-list-item-meta {
    align-items: flex-start;

    .ant-list-item-meta-avatar {
      margin-right: 16px;
    }

    .ant-list-item-meta-title {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
      }
    }

    .ant-list-item-meta-description {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .cor-comment-list-card-comment {
    >div {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }

  .ant-empty-description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .link {
    font-size: 12px;
    cursor: pointer;
    color: #000656;
  }
}

.delete-confirm-modal {
  .ant-modal-body {
    padding-top: 20px !important;
  }

  .ant-modal-confirm-btns {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;