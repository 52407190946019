@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@category-setup-container-prefix: ~'@{cor-prefix}-category-setup-container';

.@{category-setup-container-prefix} {
  padding: 40px 24px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
  .category-setup-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .category-setup-description {
    margin-top: 20px;
    font-size: 14px;
    color: #595959;
    margin-bottom: 24px;
  }

  .category-setup-space {
    margin-top: 20px;
    width: 100%;
  }

  .category-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .category-input {
    flex: 1;
    border-radius: 4px;
    height: 40px;
    margin-right: 8px;
  }
  .cor-category-color-picker{
    margin-left: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 32px;
    padding:8px 12px;
  }


  .add-button {
    width: 210px;
    height: 40px;
    margin-top: 8px;
    border-radius: 4px;
    color: #595959;
    font-weight: bold;
  }

  .start-button {
    position: fixed;
    bottom: 100px;
    right: 50px;
    height: 40px;
    width: 140px;
    border-radius: 4px;
    font-weight: bold;
  }
  .ant-select{
    margin-left: 200px;
    width: 197px !important;

  }
  .ant-select-selector{
    border-radius: 32px !important;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;