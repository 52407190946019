@import './variable.less';
@cor-prefix: 'cor';

.scroll-bar {
  padding-right: 4px;
  * {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #000656;
      border-radius: 5px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #000656;
    border-radius: 4px;
  }
}
