@import '~@/themes/func.less';
@product-subscribe-prefix: ~'@{cor-prefix}-product-subscribe';

.@{product-subscribe-prefix} {
  .ant-btn {
    border: 0;
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;

    color: #FFF;
    font-family: Sora;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 56px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;