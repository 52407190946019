@import '~@/themes/func.less';
@user-list-prefix: ~'@{cor-prefix}-mailbox-list';

.@{user-list-prefix} {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px;
  border: 2px solid var(--border, rgba(31, 44, 71, 0.07));
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &-wrap {
    overflow-y: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &-items {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;
      .scroll-bar;
    }
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;