@import '~@/themes/func.less';
@mailbox-tabs-prefix: ~'@{cor-prefix}-mailbox-tabs';
.@{mailbox-tabs-prefix} {
  &.ant-tabs {
    .ant-tabs-nav {
      border: 0;
      margin: 0;
      &::before,
      .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          display: flex;
          height: 36px;
          padding: 8px 10px;
          justify-content: center;
          align-items: center;
          gap: 7px;
          text-transform: capitalize;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 500;
          &.ant-tabs-tab-active {
            background: var(--New-Design-Gradient, linear-gradient(247deg, #e8eaff 26.95%, #f6d2f9 144.89%));
          }
          &:not(:first-of-type) {
            margin-left: 24px;
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;