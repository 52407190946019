@import '~@/themes/func.less';
@booknow-prefix: ~'@{cor-prefix}-booknow';

.@{booknow-prefix} {
  padding: 80px 96px;
  display: flex;
  justify-content: space-between;
  background-color: #000656;
  &-left {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &-1 {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--bright-pink, #fdb7e1);
    }
    &-2 {
      color: #c7fff9;
      font-family: Sora;
      font-size: 36px;
      font-weight: 600;
      line-height: 125%;
      .highlight {
        color: var(--Background, #fcfffc);
      }
      .highlight2 {
        color: #fdb7e1;
      }
    }
    &-3 {
      margin-top: 40px;
      color: var(--Light-Grey, #f4f4f4);
      leading-trim: both;
      text-edge: cap;
      font-family: Sora;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 20px */
      letter-spacing: 0.08px;
    }
  }
  &-right {
    display: flex;
    width: 816px;
    padding: 40px;
    flex-direction: column;
    gap: 32px;
    position: relative;
    border: 4px solid #c7fff9;

    .ant-btn-primary {
      border: none;
      border-radius: 0;
      margin: 0 auto;
      display: flex;
      height: 44px;
      padding: 11px 24px;
      justify-content: center;
      align-items: center;
      color: var(--Dark, #000656) !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      border-radius: 30px;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.08px;
      background: var(--Button, #ffff75) !important;
      cursor: pointer;
      transition: transform 0.2s; /* 添加过渡效果 */
    }
    &-btn:hover {
      transform: scale(1.1);
    }
    &-input {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      .ant-form-item-row {
        width: 352px;
        .ant-form-item-required {
          margin-bottom: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.5px;
          text-align: left;
          color: #fcfffc;
        }
        &-sel {
          width: 352px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          padding-top: 5px;
          height: 50px;
          border-radius: 6px;
          color: #000;
        }
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 100%;
      text-align: left;
      color: #fcfffc;
    }
    .tips1 {
      height: 4px;
      width: 4px;
      background-color: #000656;
      position: absolute;
      top: -4px;
      left: -4px;
    }
    .tips2 {
      height: 8px;
      width: 8px;
      background-color: #c7fff9;
      position: absolute;
      top: -8px;
      right: -8px;
      div {
        height: 4px;
        width: 4px;
        background-color: #000656;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .tips3 {
      height: 8px;
      width: 8px;
      position: absolute;
      bottom: -4px;
      left: -4px;
      background-color: #000656;
      div {
        height: 4px;
        width: 4px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #c7fff9;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;