@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@chat-content-item-prefix: ~'@{cor-prefix}-chat-meta-content-item';

.@{chat-content-item-prefix} {
  display: flex;
  column-gap: 16px;
  width: 100%;
  &:not(:first-of-type) {
    margin-top: 8px;
  }
  &.commenter &-timestamp-name {
    border: 2px solid transparent;
    border-radius: 8px;
    padding: 4px;

    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(90deg, #fff, #fff), linear-gradient(96.32deg, #6cf, #9898ff 49.48%, #e6b3ff);
  }
  &.Response {
    justify-content: flex-end;
    align-items: flex-start;

    .ant-avatar {
      order: 2;
    }

    .@{chat-content-item-prefix} {
      &-content {
        order: 1;
        align-items: flex-end;

        .date-and-option {
          width: 100%;
          justify-content: space-between;
          &-left {
            order: 1;
          }
        }
      }

      &-timestamp {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      &-text {
        border-radius: 16px 0px 16px 16px;
        background: linear-gradient(93deg, rgba(153, 153, 255, 0.2) 0%, rgba(0, 128, 255, 0.2) 100%);
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    row-gap: @space;
    order: 2;
    overflow: auto;
    align-items: flex-start;
    flex: 1;
    &-top {
      border-radius: 0px 16px 16px 16px;
      background: rgba(0, 51, 102, 0.05);
      padding: 16px 20px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    .ant-btn {
      padding: 0 8px;
    }
  }

  &-text {
    margin-top: 5px;
    max-width: 100%;
    color: #333;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &-subject {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .subject-1 {
        border-radius: 8px;
        background: linear-gradient(138deg, #000656 -20.61%, #99f 72.63%);
        width: 62px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
      }
      .subject-2 {
        color: #333;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin-left: 10px;
      }
    }

    p:empty {
      display: none;
    }

    pre {
      white-space: pre-wrap;
      overflow-x: auto;
      overflow-y: hidden;
      word-wrap: break-word;
      margin-bottom: 0;
    }

    .ant-btn.ant-btn-link {
      padding: 0;
      color: #1082ff;
      float: right;
    }
  }

  &-html {
    transition: all ease-in-out 0.3s;

    &.overflow {
      position: relative;
      max-height: 6.5em;
      overflow: hidden;
    }
  }

  &-btns {
    display: flex;
    column-gap: @space-2x;
    align-items: center;

    .ant-btn {
      padding: 0;
      height: 26px;
      color: #1082ff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-timestamp {
    width: 100%;
    max-width: unset;
    display: flex;
    align-items: center;
    overflow: hidden;
    &-name {
      white-space: nowrap;
    }
    column-gap: 8px;
    .date-and-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;

      &-left {
        display: flex;
        align-items: center;
        font-family: Inter;
        font-size: 14px;
        line-height: 16px;

        .name {
          color: #333;
          font-weight: 700;
        }

        .date {
          margin-right: 10px;
          margin-left: 10px;
          color: rgba(51, 51, 51, 0.6);
        }
      }

      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .collapse,
        .expand {
          display: flex;
          align-items: center;
          padding: 0px;
          height: 16px;
          transition: all ease-in-out 0.3s;

          span + .anticon {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;