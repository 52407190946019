@import '~@/themes/func.less';
@import './../PrioritySelect/index.less';
@edit-ticket-prefix: ~'@{cor-prefix}-edit-ticket';

.@{edit-ticket-prefix} {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  &-btn {
    &.ant-btn.ant-btn-sm {
      height: 37px;
      padding: 8px 10px 8px 10px;
      gap: 7px;
      border-radius: 8px;
      background: var(--Dark, rgba(0, 6, 86, 1));
      box-shadow: none;
      border: none;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.52px;
    }
  }

  &-modal {
    .ant-modal-title {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }

    &-btns {
      display: flex;
      flex-direction: row-reverse;
    }

    &-btn.ant-btn {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      border-radius: 8px;
      padding: 1.7px 12px;
      background: rgba(0, 6, 86, 1);
      box-shadow: 0px 2px 5px 0px rgba(70, 75, 146, 0.03);
      height: 36px;
    }

    &.ant-modal .ant-modal-close {
      top: 18px;
      right: 20px;

      .ant-modal-close-x {
        display: flex;
        align-items: center;
      }
    }

    // .ant-select-arrow {
    //   transform: rotate(180deg);
    //   right: 11px;
    //   margin-left: 0;
    //   // transition: all 0.1s linear;
    // }

    // .ant-select-open {
    //   .ant-select-arrow {
    //     right: 50%;
    //     margin-left: 9px;
    //     transform: none;

    //   }

    // }


    &-assignees {
      width: calc(50% - 10px) !important;

      &.ant-select:not(.ant-select-customize-input) .ant-select-selector {


        padding: 3px 10px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(231, 231, 236, 1);
        transition-property: border-color, box-shadow;
      }

      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 2px solid rgba(92, 99, 190, 1);
        padding: 2px 10px;
        box-shadow: 0px 4px 4px 0px rgba(211, 213, 237, 0.05) inset;
      }
    }

    .ant-modal-content {
      padding: 26px;
      gap: 24px;
      display: flex;
      flex-direction: column;
      border-radius: 12px;

      .ant-modal-header {
        padding: 0 24px 0 0;

        .ant-modal-title {
          font-family: Inter;
          font-size: 24px;
          font-weight: 600;
          line-height: 24px;
          color: rgba(51, 51, 51, 1);
        }
      }

      .ant-modal-body {
        padding: 0;

        .ant-form {
          row-gap: 22px;
        }

        .ant-input {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          border-radius: 6px;
          padding: 9px 10px;
          color: rgba(51, 51, 51, 1);
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(231, 231, 236, 1);
          transition-property: border-color, box-shadow;

          &:focus {
            border: 2px solid rgba(92, 99, 190, 1);
            padding: 8px 10px;
            box-shadow: 0px 4px 4px 0px rgba(211, 213, 237, 0.05) inset;
          }
        }

        .ant-form-item .ant-row {
          row-gap: 7px;
        }

        .ant-form-item-label label {
          font-family: Inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
        }

        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
          &:before {
            display: none;
          }

          &:after {
            // display: inline-block;
            // margin-left: 4px;
            // color: #ff4d4f;
            // line-height: 1;
            // content: '*';
          }
        }
      }
    }

    &-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.7);

      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: rgba(0, 6, 86, 1);


      }

      ul {
        display: flex;

        margin: 0 0 34px 0;
        padding: 0;
        column-gap: 24px;
      }

      li {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        animation: blink 1.4s infinite both;

        &:nth-of-type(1) {
          background: rgba(0, 6, 86, 1);

        }

        &:nth-of-type(2) {
          background: rgba(42, 50, 167, 1);
          animation-delay: 0.2s;

        }

        &:nth-of-type(3) {
          background: rgba(128, 136, 245, 1);
          animation-delay: 0.4s;

        }
      }
    }
  }

}

@keyframes blink {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.cor-edit-ticket-modal-priority-item,
.cor-edit-ticket-modal-priority {

  .ant-select-item-option-content,
  .ant-select-selection-item {
    span {
      width: 130px;
      display: inline-block;
      text-align: center;
      padding: 6px 0;
      border-radius: 30px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }


  }

  span[title='High'] {
    background: rgba(255, 237, 237, 1);
    color: rgba(255, 76, 76, 1);

  }

  span[title='Medium'] {
    background: rgba(255, 242, 217, 1);
    color: rgba(244, 146, 0, 1);
  }

  span[title='Low'] {
    background: rgba(255, 251, 227, 1);
    color: rgba(239, 186, 0, 1);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;