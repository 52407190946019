@import '~@/themes/func.less';
@status-category-prefix: ~'@{cor-prefix}-category-select';

.@{status-category-prefix} {

  .ant-select{
    margin-left: 200px;
    width: 197px !important;

  }
  .ant-select-selector{
    border-radius: 8px !important;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;