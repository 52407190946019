@import '~@/themes/func.less';
@text-display-prefix: ~'@{cor-prefix}-text-display';
.@{text-display-prefix} {
  border-radius: 6px;
  max-height: 260px;
  padding: 12px;
  border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
  overflow-y: auto;
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;