@import '~@/themes/func.less';
@chat-prefix: ~'@{cor-prefix}-chat';
.@{chat-prefix} {
  display: flex;
  height: 100%;
  flex-direction: column;
  // padding: 0 28px;
  position: relative;
  overflow: auto;
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;