@import '~@/themes/func.less';
@footer-prefix: ~'@{cor-prefix}-footer';

.@{footer-prefix} {
  padding: 24px 0px;
  background: #000656;

  &-inner {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  &-links,
  &-copyright {
    display: flex;
    column-gap: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #fff;

    a {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.mobile {
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: center;
    row-gap: 16px;
    flex-direction: column;

    .@{footer-prefix} {
      &-copyright {
        font-size: 12px;
      }

      &-links {
        column-gap: 24px;
        font-size: 12px;

        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;