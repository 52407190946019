@import '~@/themes/func.less';
@comment-list-prefix: ~'@{cor-prefix}-commnet';

.@{comment-list-prefix} {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  column-gap: 15px;

  &-input {
    flex: 1;
    height: 49px;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgba(31, 44, 71, 0.07);
    color: #777;
  }

  .anticon {
    color: #fff;
    font-size: 24px;
  }

  &-modal-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);

    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 6, 86, 1);


    }

    ul {
      display: flex;

      margin: 0 0 34px 0;
      padding: 0;
      column-gap: 24px;
    }

    li {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      animation: blink 1.4s infinite both;

      &:nth-of-type(1) {
        background: rgba(0, 6, 86, 1);

      }

      &:nth-of-type(2) {
        background: rgba(42, 50, 167, 1);
        animation-delay: 0.2s;

      }

      &:nth-of-type(3) {
        background: rgba(128, 136, 245, 1);
        animation-delay: 0.4s;

      }
    }
  }


}

@keyframes blink {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.comment-modal {
  .commnet-modal-btns {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-modal-content {
    padding: 26px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    .ant-modal-header {
      padding: 0 24px 0 0;

      .ant-modal-title {
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      padding: 0;
      row-gap: 22px;

      .ant-input {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        border-radius: 6px;
        padding: 9px 10px;
        color: rgba(51, 51, 51, 1);
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(231, 231, 236, 1);
        transition-property: border-color, box-shadow;

        &:focus {
          border: 2px solid rgba(92, 99, 190, 1);
          padding: 8px 10px;
          box-shadow: 0px 4px 4px 0px rgba(211, 213, 237, 0.05) inset;
        }
      }



      .ant-btn.ant-btn-default,
      .ant-btn.ant-btn-primary,
      .ant-btn.ant-btn-text {
        padding: 8px 10px 8px 10px;
        gap: 7px;
        border-radius: 8px;
        border: 0;
        height: 38px;
        min-width: 120px;
      }

      .ant-btn.ant-btn-default {
        border: 1px solid rgba(31, 44, 71, 0.07);
        color: rgba(51, 51, 51, 0.6);
      }

      .ant-btn.ant-btn-primary {
        background: rgba(0, 6, 86, 1);
      }

      .ant-btn.ant-btn-text {
        text-decoration: underline;
        color: rgba(51, 51, 51, 0.6);

        &:hover {
          background: transparent;
        }
      }
    }
  }

  .comment-input {
    // margin-top: 20px;
    // margin-bottom: 16px;
  }

  .upload-area {
    margin-bottom: 16px;
    display: block;
  }

  .send-button {}

  .link {
    font-size: 12px;
    cursor: pointer;
    color: #000656;
  }

  .attachment-delete {
    padding: 0;
    color: #ff4d4f;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;