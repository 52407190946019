@import '~@/themes/func.less';
@import '~@/themes/variable.less';
.dashboard {
  // background: url('./assets/bg.png') center no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  width: 100%;
  width: var(--doc-width);
  overflow: hidden;
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 24px;

    &.mobile {
      padding-right: 0;
      width: 100%;
      padding-bottom: 80px;
      justify-content: space-between;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;