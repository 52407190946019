@import '~@/themes/func.less';
@settings-prefix: ~'@{cor-prefix}-settings';

.@{settings-prefix} {
  display: flex;
  flex-direction: column;
  row-gap: @space-5x;
  padding: 0 @space-10x;
  width: 100%;
  height: calc(100%);
  position: relative;
  &-color{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    margin-left: auto; 
  }
  &-head {
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }
  // background-color: #fff;
  &-empty {
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 35px;

    .ant-empty-image {
      width: 238px;
      height: 238px;
    }

    .ant-empty-description {
      font-family: Inter;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: rgba(51, 51, 51, 1);
    }
  }

  &-tabs {
    padding-top: 100px;

    .ant-tabs-nav {
      margin: 0;

      &:before {
        border-bottom: 0.1rem solid #D9D9D9;
      }

      .ant-tabs-nav-wrap {
        overflow: initial;
      }

      .ant-tabs-ink-bar {
        background-color: #000656;
        bottom: -3px;
        height: 3px;
      }

    }


    .ant-tabs-nav-list {
      padding: 0 27px;
    }

    .ant-tabs-tab {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      padding: 15px 8px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000656;
    }

  }

  &-title {
    padding: 24px 0;
    font-size: 24px;
    font-weight: 700;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

  }

  &-status {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 8px 20px;
    border-radius: 30px;
    text-transform: capitalize;

    &.Medium {
      background: rgba(255, 242, 217, 1);
      color: rgba(244, 146, 0, 1);
    }

    &.Low {
      background: rgba(255, 251, 227, 1);
      color: rgba(239, 186, 0, 1);
    }

    &.High {
      background: rgba(255, 237, 237, 1);
      color: rgba(255, 76, 76, 1);
    }

    &.completed {
      background: rgba(236, 255, 213, 1);
      color: rgba(69, 181, 0, 1);
    }

    &.processing {
      background: rgba(232, 245, 255, 1);
      color: rgba(0, 128, 255, 1);
    }

    &.pending {
      background: rgba(236, 236, 236, 1);
      color: rgba(111, 111, 111, 1);
    }
  }

  .action-section {
    position: absolute;
    bottom: 20px;
  }

  .action-section-input {
    width: 600px;
  }

  .cor-data-table {
    .ant-table {
      background: transparent;

    }

    .ant-table-thead {
      border-radius: 15px 15px 0px 0px;
      background: rgba(245, 245, 245, 1);
      overflow: hidden;
    }

    .ant-table .ant-table-thead .ant-table-cell {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: rgba(51, 51, 51, 0.6);
      background: rgba(245, 245, 245, 1);

      &:first-child {
        border-radius: 15px 0px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 15px 0px 0px;
      }
    }


    .ant-table .ant-table-tbody .ant-table-cell {
      padding: 15px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;
      color: rgba(51, 51, 51, 1);
    }

    .ant-table-tbody>tr>td {
      border-bottom: 1px solid rgba(31, 44, 71, 0.07)
    }
  }

  // .custom-table-row {
  //   background-color: red;
  // }

  .custom-table-row:hover {
    cursor: pointer;
  }

  .ant-input {
    border-radius: 2px;
    border: 1px solid var(--border, #e0dbdb) !important;
    background: var(--bg, #fff) !important;
    display: flex;
    padding: 8px 14px !important;
    gap: 12px;
    .font14;

    &:hover {
      // border: 1px solid var(--p-1, #000656) !important;
    }
  }

  .cor-data-table {

    .ant-pagination-item,
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next,
    .ant-pagination-total-text {
      border: 0;
      height: 24px;
      line-height: 24px;
      min-width: 24px;
    }

    .ant-pagination-prev {
      margin-right: 4px;
    }

    .ant-pagination-item {
      font-family: Inter;
      font-size: 10px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      // height: 24px;
      // line-height: 24px;
      // min-width: 24px;
      margin-right: 4px;
    }

    .ant-pagination-total-text {
      flex: 1;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      color: rgba(51, 51, 51, 0.6);
      margin-right: 4px;
    }

    .ant-pagination-options {
      margin-left: 4px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 24px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      // color: rgba(51, 51, 51, 1);
    }

    .ant-select-selection-item {
      line-height: 24px;
    }

    .ant-pagination-item-active {
      background: rgba(0, 6, 86, 1);
      border-radius: 50%;

      a {
        color: #fff;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;