.ant-table {
  border-radius: @space-2x;
  overflow: hidden;
  .ant-table-thead {
    background-color: #fff;
    padding-left: 0;
    border-bottom: 1px solid #d9d9d9 !important;
    .ant-table-cell {
      background: var(--bg, #f8f9fc);
      border: none !important;
      border-bottom: 1px solid #d9d9d9 !important;
      .font12;
      color: var(--text-2, rgba(51, 51, 51, 0.6));
      &::before {
        display: none;
      }
      &:not(:first-of-type) {
        text-align: right;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 8px;
      height: 48px;
      &:not(:first-of-type) {
        text-align: right;
      }
    }
  }
  .ant-table-pagination.ant-pagination {
    align-items: center;
    margin: 12px 0;

    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-total-text {
      border-radius: 6px;
      height: 24px;
      min-width: 24px;
      line-height: 22px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      button {
        border: 0;
      }
    }

    .ant-pagination-total-text {
      flex: 1;
      color: rgba(51, 51, 51, 0.6);
      font-size: 12px;
      font-weight: 400;
    }
  }
}
