/*
 * antd button reset start
*/
.ant-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: @space;
  box-shadow: none;
  outline: none;
  height: 40px;
  padding: @space-2x @space-4x;
  gap: 8px;

  &.ant-btn-primary {
    background: var(--p-1, #000656);
    .font14;
    font-weight: 700;
    box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
    svg path {
      fill: #fff;
    }
    &:disabled {
      cursor: not-allowed;
      color: var(--white, #fff);
      &:hover {
        background: var(--p-1, #000656);
        color: var(--white, #fff);
      }
    }
  }
  &.ant-btn-ghost {
    border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    color: #000656;
    background: var(--white, #fff);
    box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
    svg path {
      fill: var(--p-1, #000656);
    }
    &:hover {
      font-weight: 700;
      svg path {
        fill: var(--p-1, #000656);
      }
    }
  }
  &.ant-btn-text {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--p-1, #000656);
      color: var(--white, #fff);
      svg path {
        fill: var(--white, #fff);
      }
    }
  }
  &.ant-btn-sm {
    padding: 4px 8px;
    height: 32px;
    .font12;
    border-radius: 8px;
  }
  &.cor-save {
    margin-top: 24px;
    border-radius: 8px;
    background: linear-gradient(90deg, #9498ff 0%, #0981ff 100%);
    display: flex;
    justify-content: center;
    .font20;
    font-weight: 700;
    color: var(--white, #fff);
    height: 64px;
    width: 100%;
    &:hover {
      background: linear-gradient(90deg, #9498ff 0%, #0981ff 100%);
      color: var(--white, #fff);
    }
  }
}
