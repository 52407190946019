@import '~@/themes/func.less';
@session-detail-prefix: ~'@{cor-prefix}-session-detail';
.@{session-detail-prefix} {
  &-wrap {
    flex: 1;
    padding: 0 32px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    position: relative;
    .cor-aigc-bot-ai {
      position: absolute;
      top: 12px;
      right: 24px;
    }
  }
  flex: 1;
  padding-top: 28px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  &.mobile {
    padding: 0px;
    row-gap: 0;
  }
  &-skeleton {
    padding: 0 12px;
    &-item {
      display: flex;
      column-gap: 16px;
      justify-content: flex-start;
      &.even {
        justify-content: flex-end;
        .ant-skeleton {
          &:first-of-type {
            order: 2;
          }
          &:last-of-type {
            order: 1;
          }
        }
      }
      .ant-skeleton {
        margin: 0;
        &:nth-of-type(2) {
          width: 400px;
          border-radius: 8px;
          overflow: hidden;
        }
        .ant-skeleton-title {
          height: 77px;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;