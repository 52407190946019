@import '~@/themes/func.less';
@report-prefix: ~'@{cor-prefix}-report';

.@{report-prefix} {
  display: flex;
  flex-direction: column;
  row-gap: @space-5x;
  padding: 0 @space-10x;
  width: 100%;
  height: calc(100%);
  position: relative;
  &-head {
    display: flex;
    gap: 50px;
  }
  // background-color: #fff;


  &-tabs {
    padding-top: 48px;
      .ant-empty{
        display: none !important; 
      }
    .ant-tabs-nav {
      margin: 0;

      &:before {
        border-bottom: 0.1rem solid #D9D9D9;
      }

      .ant-tabs-nav-wrap {
        overflow: initial;
      }

      .ant-tabs-ink-bar {
        background-color: #000656;
        bottom: -3px;
        height: 3px;
      }

    }


    .ant-tabs-nav-list {
      padding: 0 27px;
    }

    .ant-tabs-tab {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      padding: 15px 8px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000656;
    }

  }

}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;