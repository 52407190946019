// colors
@primary-color: #000656;
@text-color: #334466;
@text-light: #7a8599;

// border
@border-color: #dae2f2;

// container
@page-max-width: 1280px;
@page-padding: 24px;
@container-max-width: @page-max-width - @page-padding * 2;

@container-width: 1280px;

.f-j-c {
  display: flex;
  justify-content: center;
}

.font24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.font32 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}

.font14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.font16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}

.font12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.font20 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

@space: 4px;
@space-2x: 8px;
@space-3x: 12px;
@space-4x: 16px;
@space-5x: 20px;
@space-6x: 24px;
@space-7x: 28px;
@space-8x: 32px;
@space-9x: 36px;
@space-10x: 40px;
