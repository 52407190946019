@import '~@/themes/func.less';
@post-comment-list-prefix: ~'@{cor-prefix}-post-comment-list';
.@{post-comment-list-prefix} {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;
  overflow: hidden;
  &.mobile {
    padding: 12px;
    row-gap: 12px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    &-name {
      display: flex;
      column-gap: 8px;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      &-right {
        &-bottom {
          font-size: 14px;
          font-weight: 400;
          display: flex;
          column-gap: 8px;
        }
      }
    }
    &-btns {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
  }
  .cor-comment-list-wrap {
    flex: 1;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;