@import '~@/themes/func.less';
@setting-default-prefix: ~'@{cor-prefix}-setting-default';

.@{setting-default-prefix} {
  .ant-btn.ant-btn-sm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--text-1, #333);
    font-size: 12px;
    font-weight: 400;
    /* 133.333% */
    border-radius: 6px;
    border: 1px solid var(--border, #000656);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;