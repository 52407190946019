@import '~@/themes/func.less';
@delete-ticket-prefix: ~'@{cor-prefix}-ticket-detail';

.@{delete-ticket-prefix} {
  height: var(--doc-height);
  margin: 0 25px;

  &-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    margin: 50px 0 0;
    padding-bottom: 12px;

    .back-button {
      padding: 0 0 0 15px;
      width: initial;
      color: rgba(51, 51, 51, 1);

      &:hover {
        background: initial;

        svg path {
          fill: rgba(51, 51, 51, 1);
        }
      }
    }

    span {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
  }

  &-body {
    margin-top: 36px;
    display: flex;
    justify-content: stretch;
    height: calc(100vh - 200px);
  }

  &-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-right: 2px;
    .ant-card-body {
      box-sizing: border-box;
      flex: 1;
      overflow-y: scroll;
      width: 100%;
      display: flex;
      flex-direction: column;

      .ant-upload {
        margin-top: 16px;

        .ant-btn {
          padding: 8px 10px;
          gap: 10px;
          border-radius: 8px;
          border: 2px solid rgba(31, 44, 71, 0.07);
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          height: initial;
          color: rgba(51, 51, 51, 0.6);

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .inner-card-right {
    margin-left: 26px;
    width: 415px;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 38px 0 73px;

    &-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      color: rgba(51, 51, 51, 0.6);
      margin-bottom: 0;

      & + .comments {
        height: 100%;
        padding-right: 24px;
        overflow-y: auto;
      }
    }

    .comments-input {
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
    }
  }

  .description {
    white-space: pre-wrap;
    word-wrap: break-word;

    .description-span {
      width: 500px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  p {
    margin: 8px 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: rgba(51, 51, 51, 1);
  }

  strong {
    font-family: Inter;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.6);
    margin-right: 15px;
  }

  .ant-input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    padding: 9px 14px;
    border-radius: 6px;
    border: 1px solid rgba(31, 44, 71, 0.07);
    background: rgba(253, 253, 255, 1);
    gap: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(51, 51, 51, 1);

    &:hover {
      border: 1px solid var(--p-1, #000656) !important;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 3px 14px;
  }

  .attachment-list {
    margin-top: 16px;
  }

  .attachment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    > div {
      color: rgba(0, 0, 0, 1);
    }
  }

  .attachment-item:last-child {
    border-bottom: none;
  }

  .attachment-link {
    color: #1890ff;
    text-decoration: none;
    font-size: 14px;
  }

  .attachment-link:hover {
    text-decoration: underline;
  }

  .attachment-delete {
    padding: 0;
    color: #ff4d4f;
  }

  &-attachments {
    .attachment-no-more {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: rgba(51, 51, 51, 0.6);
      margin-top: 24px;
    }

    .ant-btn.ant-btn-default {
      border: 1px solid rgba(31, 44, 71, 0.07);
      color: rgba(51, 51, 51, 0.6);
      padding: 8px 10px 8px 10px;
      gap: 7px;
      border-radius: 8px;
      height: 38px;
      min-width: 120px;
    }
  }

  &-title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: rgba(51, 51, 51, 0.6);
  }

  &-name {
    margin-top: 14px;

    > span {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
  }

  &-description {
    // span,
    // textarea.ant-input {
    //   min-height: 160px;

    // }

    span {
      display: block;
      margin-top: 36px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
    }
  }

  &-emails {
    &.isEdit {
      display: flex;
      column-gap: 20px;

      .cor-ticket-detail-email {
        width: 50%;
        flex-direction: column;
        align-items: flex-start;

        & + .cor-ticket-detail-email {
          margin-top: 0;
        }
      }
    }
  }

  &-email {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  &-email + &-email {
    margin-top: 10px;
  }

  &-assignees {
    display: flex;
    column-gap: 10px;
    align-items: center;
    width: calc(50% - 10px);

    .ant-select.ant-select-multiple.ant-select-show-search {
      display: flex;

      .ant-select-selector {
        flex: 1;
      }
    }

    .assignees-box {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 8px;
    }

    &.isEdit {
      display: block;
    }
  }

  &-status,
  &-priority {
    display: flex;
    column-gap: 15px;
    margin-top: 24px;

    &-dropdown {
      padding: 10px 8px;
      height: 30px;
      // min-width: 120px;
      gap: 10px;
      border-radius: 8px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      text-align: center;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-width: 1px;
      border-style: solid;

      .ant-space-item:first-child {
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .ant-space-item:nth-of-type(2):nth-last-of-type(1) {
        svg {
          display: none;
        }

        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-width: 0 0 1px 1px;
        border-style: solid;
        transform: rotate(-45deg);
        position: relative;
        top: -1px;
      }

      &.ant-dropdown-open {
        .ant-space-item:nth-of-type(2):nth-last-of-type(1) {
          transform: rotate(135deg);
          top: 1px;
        }
      }

      &.completed {
        background: rgba(236, 255, 213, 1);
        color: rgba(69, 181, 0, 1);
        border-color: rgba(69, 181, 0, 0.1);

        .ant-space-item {
          &:before {
            background-color: rgba(69, 181, 0, 1);
          }
        }
      }

      &.processing {
        background: rgba(232, 245, 255, 1);
        color: rgba(0, 128, 255, 1);
        border-color: rgba(0, 128, 255, 0.1);

        .ant-space-item {
          &:before {
            background-color: rgba(0, 128, 255, 1);
          }
        }
      }

      &.pending {
        background: rgba(236, 236, 236, 1);
        color: rgba(111, 111, 111, 1);
        border-color: rgba(111, 111, 111, 0.1);

        .ant-space-item {
          &:before {
            background-color: rgba(111, 111, 111, 1);
          }
        }
      }

      &.Medium {
        background: rgba(255, 242, 217, 1);
        color: rgba(244, 146, 0, 1);
        border-color: rgba(244, 146, 0, 0.1);

        .ant-space-item {
          &:before {
            background-color: rgba(244, 146, 0, 1);
          }
        }
      }

      &.Low {
        background: rgba(255, 251, 227, 1);
        color: rgba(239, 186, 0, 1);
        border-color: rgba(239, 186, 0, 0.1);

        .ant-space-item {
          &:before {
            background-color: rgba(239, 186, 0, 1);
          }
        }
      }

      &.High {
        background: rgba(255, 237, 237, 1);
        color: rgba(255, 76, 76, 1);
        border-color: rgba(255, 76, 76, 0.1);

        .ant-space-item {
          &:before {
            background-color: rgba(255, 76, 76, 1);
          }
        }
      }
    }
  }

  &-dropdown-menu.ant-dropdown-menu {
    padding: 5px;
    // gap: 5px;
    border-radius: 8px;
    border: 1px solid rgba(31, 44, 71, 0.07);

    .ant-dropdown-menu-item {
      padding: 0;

      &:not(:first-child) {
        margin-top: 5px;
      }
    }

    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-submenu-title-selected {
      color: transparent;
      background: transparent;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      &:hover {
        background: transparent;
      }

      span {
        display: inline-block;
        text-align: center;
        padding: 6px 8px;
        border-radius: 8px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        // background: rgba(255, 237, 237, 1);
        // color: rgba(255, 76, 76, 1);
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-right: 6px;
        }

        &:after {
          content: '';
          display: none;
          width: 9px;
          height: 6px;
          border-width: 0 0 1px 1px;
          border-style: solid;
          transform: rotate(-45deg);
          position: absolute;
          right: 9px;
        }

        &:hover {
          &:after {
            display: inline-block;
          }
        }
      }

      &[data-menu-id*='completed'] span {
        &:hover {
          background: rgba(236, 255, 213, 1);
        }

        color: rgba(69, 181, 0, 1);

        &:before {
          background-color: rgba(69, 181, 0, 1);
        }
      }

      &[data-menu-id*='processing'] span {
        &:hover {
          background: rgba(232, 245, 255, 1);
        }

        color: rgba(0, 128, 255, 1);

        &:before {
          background-color: rgba(0, 128, 255, 1);
        }
      }

      &[data-menu-id*='pending'] span {
        &:hover {
          background: rgba(236, 236, 236, 1);
        }

        color: rgba(111, 111, 111, 1);

        &:before {
          background-color: rgba(111, 111, 111, 1);
        }
      }

      &[data-menu-id*='Medium'] span {
        &:hover {
          background: rgba(255, 242, 217, 1);
        }

        color: rgba(244, 146, 0, 1);

        &:before {
          background-color: rgba(244, 146, 0, 1);
        }
      }

      &[data-menu-id*='Low'] span {
        &:hover {
          background: rgba(255, 251, 227, 1);
        }

        color: rgba(239, 186, 0, 1);

        &:before {
          background-color: rgba(239, 186, 0, 1);
        }
      }

      &[data-menu-id*='High'] span {
        &:hover {
          background: rgba(255, 237, 237, 1);
        }

        color: rgba(255, 76, 76, 1);

        &:before {
          background-color: rgba(255, 76, 76, 1);
        }
      }
    }
  }

  &-name,
  &-description,
  &-email,
  &-assignees,
  &-status,
  &-priority,
  &-attachments {
    > p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      text-align: left;
      color: rgba(51, 51, 51, 0.6);
    }
  }

  &-footer {
    flex: 1 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    > div {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }

    &-time {
      display: flex;
      align-items: center;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      color: rgba(51, 51, 51, 0.6);
      column-gap: 15px;
    }

    .ant-btn.ant-btn-primary,
    .ant-btn.ant-btn-text {
      padding: 8px 10px 8px 10px;
      gap: 7px;
      border-radius: 8px;
      border: 0;
      height: 38px;
      min-width: 120px;
    }

    .ant-btn.ant-btn-primary {
      background: var(--Dark, rgba(0, 6, 86, 1));
    }

    .ant-btn.ant-btn-text {
      text-decoration: underline;
      color: rgba(51, 51, 51, 0.6);

      &:hover {
        background: transparent;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;