.ant-modal {
  border-radius: 8px;
  padding-bottom: 0;
  overflow: hidden;
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
    .ant-modal-header {
      padding: 24px;
      border-bottom: none;
      padding-bottom: 0px;
      .ant-modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .ant-modal-body {
      padding: 24px;
      padding-top: 0;
    }
  }
  .ant-modal-close {
    right: 24px;
    top: 24px;
    .ant-modal-close-x {
      width: 24px;
      height: 24px;
      line-height: 24px;
      &:hover {
        path {
          fill: var(--p-1, #000656);
        }
      }
    }
  }
}
