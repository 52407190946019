@import '~@/themes/func.less';
@header-prefix: ~'@{cor-prefix}-header';

.@{header-prefix} {
  // position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 37px 56px;
  &-left {
    display: flex;
    align-items: center;
    column-gap: 30px;
    &-btns {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }
  &-btns {
    display: flex;
    column-gap: 32px;
    align-items: center;

    .ant-btn {
      display: flex;
      padding: 11px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      text-align: center;
      font-family: Sora;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.08px;
      height: 44px;
      padding: 11px 24px;
      border-radius: 30px;
    }

    .ant-btn.ant-btn-text:hover {
      background-color: transparent;
      color: #333;

      // box-shadow: none;
      // border: none;
    }

    .ant-btn.ant-btn-primary {
      background-color: #000656;
      color: #ffffff;
      box-shadow: none;
      border: none;
    }
    .ant-btn.ant-btn-ghost {
      color: #000656;
      border: 2px solid #000656;
    }
  }
  .ant-btn.ant-btn-text {
    border: none;
    display: flex;
    width: 90px;
    padding: 11px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000656;
    text-align: center;
    font-family: Sora;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    background: none;
    &:hover {
      background: none;
      color: #000656;
      font-weight: 600;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;