@import '~@/themes/func.less';
@data-search-prefix: ~'@{cor-prefix}-data-search';
.@{data-search-prefix} {

  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 456px;
  .search-input {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0 11px;
    height: 38px; 
    .ant-input {
      font-size: 14px;
      padding: 0 4px;
      background: #fff;
      border: none !important;
    }
      .ant-input-suffix{
        background: #fff;
      }
      .ant-input::placeholder {
        color: #666 !important;
      }
  }
  
  .search-icon {
    color: #bfbfbf; /* Icon color */
    margin-right: 4px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;