@import '~@/themes/func.less';
@loading-prefix: ~'@{cor-prefix}-loading';
.@{loading-prefix} {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;