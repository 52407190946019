@import '~@/themes/func.less';
@plugin-sessions-prefix: ~'@{cor-prefix}-plugin-sessions';
.@{plugin-sessions-prefix} {
  display: flex;
  height: calc(var(--doc-height) - 62px - 16px - 24px);
  align-items: stretch;
  padding-top: 32px;
  &-left {
    border-right: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    width: 450px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    overflow: hidden;
    row-gap: 12px;
    padding-right: 30px;
    &-resizer {
      width: 6px;
      height: 100%;
      background-color: #ccc;
      cursor: ew-resize;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &-resizer.visible {
      opacity: 1;
    }
    &.open {
      transform: translateX(0);
    }
    &.closed {
      width: 0px;
      overflow: hidden;
      transform: translateX(-100%);
    }
    &-search {
      width: 600px;
      padding: 24px 24px 0 24px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f8f9fc;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #236fff;
      border-radius: 5px;
    }
  }

  &-right {
    flex: 1;
    display: flex;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;