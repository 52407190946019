@import '~@/themes/func.less';
@mobilefeatures-prefix: ~'@{cor-prefix}-mobilefeatures';
.@{mobilefeatures-prefix} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-top {
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 16px;
    background-color: #000656;
    position: relative;
    &-dian1 {
      width: 12px;
      height: 12px;
      background-color: #c7fff9;
      position: absolute;
      top: 37px;
      right: 63px;
    }
    &-dian2 {
      width: 6px;
      height: 6px;
      background-color: #f6ee36;
      position: absolute;
      top: 62px;
      right: 31px;
    }
    &-dian3 {
      width: 12px;
      height: 12px;
      background-color: #f6ee36;
      position: absolute;
      bottom: 59px;
      left: 54px;
    }
    &-dian4 {
      width: 6px;
      height: 6px;
      background-color: #c7fff9;
      position: absolute;
      bottom: 38px;
      left: 101px;
    }
    &-1 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      color: #fcfffc;
    }
    &-2 {
      margin-top: 19px;
      width: 100%;
      background: #fff3fa;
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      div:nth-of-type(1) {
        font-size: 28px;
        font-weight: 600;
        line-height: 100%;
      }
      div:nth-of-type(2) {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }
    &-3 {
      margin-top: 32px;
      color: var(--Background, #fcfffc);
      text-align: center;
      font-family: Sora;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  &-cantainer {
    margin-top: 130px;
    padding: 32px 16px;
    border: 2px solid #000656;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &-head {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 43px;
      border: 2px solid #000656;
      border-bottom: none;
      gap: 4px;
      position: relative;
      &-tips1 {
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
        display: flex;
        column-gap: 8px;
        align-items: center;
        svg {
          width: 12px;
          height: 12px;
        }
      }
      &-tips2 {
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
      }
      &-left {
        height: 3px;
        width: 3px;
        position: absolute;
        top: -3px;
        left: -3px;
        z-index: 2;
        background-color: #fff;
      }
      &-right {
        height: 4px;
        width: 4px;
        position: absolute;
        top: -3px;
        right: -3px;
        z-index: 2;
        background-color: #fff;
        div {
          height: 2px;
          width: 2px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #000656;
        }
      }
    }
    &-head1 {
      width: 92px;
      background: linear-gradient(90deg, #ffffe3; #fffff3);
      position: absolute;
      top: -42.5px;
      left: -2px;
    }
    &-head2 {
      align-items: center;
      width: 40%;
      background: linear-gradient(90deg, #E0FFFB; #e0fffb);
      position: absolute;
      top: -42.5px;
      left: 30%;
    }
    &-head3 {
      width: 110px;
      background: linear-gradient(90deg, #f6eef5; #fee8fb);
      position: absolute;
      top: -42.5px;
      right: -2px;
    }
    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      &-1 {
        color: var(--Dark, #000656);
        font-family: Sora;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
      }
      &-2 {
        color: var(--Grey-Body-Text, #4c4f71);
        font-family: Sora;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */
        letter-spacing: -0.07px;
      }
      &-3 {
        display: flex;
        padding: 8.828px 19.261px;
        justify-content: center;
        align-items: center;
        gap: 8.025px;
        border-radius: 24.076px;
        background: var(--Dark, #000656);
        color: var(--Background, #fcfffc);
        text-align: center;
        font-family: Sora;
        font-size: 12px;
        font-weight: 700;
        line-height: 150%;
        transition: transform 0.2s; /* 添加过渡效果 */
      }
      &-3:hover {
        transform: scale(1.1);
      }
    }
    &-right {
      margin-top: 32px;
      width: 100%;
      height: 236px;
    }
  }
  &-cantainer:nth-of-type(1) {
    margin-top: 40px;
  }
  &-type1 {
    background: linear-gradient(281.55deg, #fffffd 3.89%, #ffffe3 136.79%, #feffc8 159.35%);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
  &-type2 {
    background: linear-gradient(358.73deg, #fffef9 -60.44%, #e0fffb 146.26%, #c4fff8 168.83%);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
  &-type3 {
    background: linear-gradient(77.66deg, #fcfffa 3.4%, #fee8fb 107.37%, #ffd8fb 125.49%);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;