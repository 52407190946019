@import '~@/themes/func.less';
@comment-item-prefix: ~'@{cor-prefix}-comment-item';
.@{comment-item-prefix} {
  position: relative;
  padding: 12px;
  padding-top: 8px;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  column-gap: 12px;
  align-items: flex-start;
  &.mobile {
    padding: 0;
    .@{comment-item-prefix} {
      &-right {
        row-gap: 4px;
        &-top {
          padding: 8px;
        }
      }
    }
  }

  &-replies {
    width: 100%;
    border-left: 1px solid var(--border, rgba(31, 44, 71, 0.07));
  }

  &-name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 16px;
    border-bottom: 1px solid transparent;
    color: #333;

    &-left {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.active {
        color: #000656;
      }
    }

    &-right {
      color: rgba(51, 51, 51, 0.6);
      font-family: Inter;
      border-radius: 0px 16px 16px 16px;
      background: rgba(0, 51, 102, 0.05);
      font-size: 14px;
      line-height: normal;
      font-style: normal;
      font-weight: 400;
    }
  }

  &-content {
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
  }

  &-tags {
    display: flex;
    column-gap: 4px;
    align-items: center;
    padding: 4px 0;
    svg {
      path {
        fill: #236fff;
      }
    }
  }

  &-left {
    display: flex;
    align-items: center;
  }

  &-right {
    flex: 1;
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .ant-typography {
      margin-bottom: 0;
    }
    &-top {
      padding: 16px;
      border-radius: 0px 16px 16px 16px;
      background: rgba(0, 51, 102, 0.05);
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    font-size: 14px;
    line-height: 16px;
    font-family: 400;
    &-top {
      display: flex;
      column-gap: 8px;
      align-items: center;
      font-weight: 700;
      .cor-formated-date {
        font-weight: 400;
      }
    }
  }
  &-modal.ant-modal {
    .ant-modal-content {
      .ant-modal-title {
        font-weight: 400;
      }
      .ant-modal-body {
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;