@import '~@/themes/func.less';
@subscription-prefix: ~'@{cor-prefix}-subscription';
.@{subscription-prefix} {
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  &-account {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    dl {
      width: 400px;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      dt {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
      }
    }
    &-period {
    }
  }
  &-invoices {
    border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    padding-top: 16px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;