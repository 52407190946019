@import '~@/themes/func.less';
@bot-chat-item-prefix: ~'@{cor-prefix}-bot-chat-item';
.@{bot-chat-item-prefix} {
  border-radius: 8px;
  display: flex;
  column-gap: 16px;
  padding: 16px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 24px;
  }
  &.assistant {
    background: #e3efff;
  }

  .sb-avatar {
    order: 1;
  }

  &-content {
    display: flex;
    flex-direction: column;
    row-gap: @space-2x;
    flex: 1;
    order: 2;
  }
  &-text {
    max-width: 100%;
    font-size: 16px;
    font-weight: 300;
    word-break: break-all;
    word-wrap: break-word;
    line-height: 26px;
    &.generating {
      padding-bottom: 12px;
    }
  }
}
@generating-prefix: ~'@{cor-prefix}-generating';
.@{generating-prefix} {
  border-radius: 99px;
  width: 16px;
  height: 16px;
  background: linear-gradient(90deg, #99f 0%, #000656 100%);
  animation: generating 2s ease-in infinite;
}
@keyframes generating {
  20% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;