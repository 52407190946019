@import '~@/themes/func.less';
@about-prefix: ~'@{cor-prefix}-about';
.@{about-prefix} {
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fcfffc;
  margin: 0 auto;
  position: relative;
  .cor-header {
    width: 100%;
  }
  &-content {
    width: 1328px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      margin-top: 32px;
    }
    h4 {
      font-size: 36px;
      font-weight: 700;
      margin-top: 24px;
    }
    p {
      margin-top: 12px;
      font-size: 24px;
      line-height: 32px;
    }
  }
  &.mobile {
    width: 100%;
    .@{about-prefix} {
      &-content {
        width: 100%;
        padding: 16px;
        h3 {
          text-align: center;
          font-size: 32px;
          font-weight: 700;
          margin-top: 24px;
        }
        h4 {
          font-size: 24px;
          font-weight: 700;
          margin-top: 18px;
        }
        p {
          margin-top: 12px;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;