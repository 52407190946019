@import '~@/themes/func.less';
@paginator-prefix: ~'@{cor-prefix}-paginator';
.@{paginator-prefix} {
  &.ant-pagination {
    width: unset;
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: flex-end;
    .ant-pagination-total-text {
      color: var(--text-2, rgba(51, 51, 51, 0.6));
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 500;
      line-height: 120%;
      margin: 0;
    }
    .ant-pagination-simple-pager {
      display: none;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      margin: 0;
      min-width: unset;
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: '#333333';
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;