@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@template-prefix: ~'@{cor-prefix}-template';

.@{template-prefix} {
  display: flex;
  flex-direction: column;

  &.ant-card {
    .ant-card-head {
      .ant-typography {
        color: var(--text-2, rgba(51, 51, 51, 0.60));
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }


  &-actions {
    display: flex;
    column-gap: @space-2x;
    justify-content: flex-end;
    align-items: center;

    .cor-setting-default {
      .ant-btn.ant-btn-sm {
        height: auto;
        padding: 3px 8px;
      }
    }

    .cor-delete-template,
    .cor-edit-template {
      span {
        display: none;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;