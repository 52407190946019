.App {
  .ant-card {
    border-radius: 8px;
    .ant-card-head {
      min-height: 40px;
      &-title {
        padding: 8px 0;
      }
      .ant-card-extra {
        padding: 8px 0;
      }
    }
    .ant-card-body {
      padding: 12px 24px;
    }
  }
}
