@import '~@/themes/func.less';
@comment-list-prefix: ~'@{cor-prefix}-comment-list';

.@{comment-list-prefix} {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding: 8px 0;
  &.mobile {
    row-gap: 12px;
    max-height: 400px;
  }
  .ant-empty {
    margin-top: 48px;
  }
  &-wrap {
    overflow-y: auto;

    * {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f8f9fc;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #236fff;
        border-radius: 5px;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f8f9fc;
    }

    &::-webkit-scrollbar-thumb {
      background: #236fff;
      border-radius: 4px;
    }
  }
  &-skeleton {
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: stretch;
    &-item {
      display: flex;
      column-gap: 24px;
      .ant-skeleton:nth-of-type(2) {
        flex: 1;
        .ant-skeleton-button {
          width: 80%;
          height: 100px;
        }
      }
    }
  }
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;