@import '~@/themes/func.less';
@plugin-prefix: ~'@{cor-prefix}-plugin';
.@{plugin-prefix} {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  &-step {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &-header {
      display: flex;
      column-gap: 16px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      column-gap: 48px;
      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 99px;
        border: 1px solid var(--border, #000656);
      }
      &-left {
        display: flex;
        align-items: center;
        column-gap: 4px;
        .head {
          padding: 4px;
          border-radius: 4px;
          color: #fff;
          background: linear-gradient(138deg, #000656 -20.61%, #99f 72.63%);
        }
      }
    }
    &-content {
      padding-left: 24px;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;