@import '~@/themes/func.less';
@products-prefix: ~'@{cor-prefix}-products';

.@{products-prefix} {
  margin-top: 80px;
  padding-bottom: 168px;

  background: url('./assets/bg03.png') center bottom no-repeat;
  background-size: cover;

  &-inner {
    width: 1200px;
    margin: 0 auto;
  }

  &-title {
    display: flex;
    color: #333;
    font-family: Sora;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    justify-content: center;
    align-items: center;

    .highlight {
      margin-left: 8px;
      background: linear-gradient(93deg, #000656 0.96%, #99f 100.45%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    svg {
      margin: 0 16px;
    }
  }

  &-list {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-item {
    width: 378px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    height: 488px;
    border-radius: 32px;
    border: 1px solid #e0e2eb;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
    transition: border 300ms ease-in;

    .contact-us {
      display: flex;
      padding: 16px 0px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      background: #3bf;
      color: #fff;
      font-family: Sora;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-title {
      display: flex;
      font-family: Sora;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      column-gap: 8px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:nth-of-type(1) {
      color: #000656;

      .ant-btn {
        background: #000656;
      }

      &:hover {
        border: 4px solid #000656;
      }
    }

    &:nth-of-type(2) {
      color: #9999ff;

      .ant-btn {
        background: #9999ff;
      }

      &:hover {
        border: 4px solid #9999ff;
      }
    }

    &:nth-of-type(3) {
      color: #33bbff;

      &:hover {
        border: 4px solid #33bbff;
      }
    }

    &-price {
      margin: 32px 0 48px;
      color: rgba(51, 51, 51, 0.6);
      font-family: Sora;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 40px;

      &.custom {
        display: flex;
        align-items: flex-end;
        color: rgba(51, 51, 51, 1);
      }

      .highlight {
        color: #333;
        font-size: 48px;
        font-weight: 700;
        line-height: 40px;
      }
    }

    &-desc {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      margin-top: 48px;

      dl {
        margin: 0;
        display: flex;
        column-gap: 8px;
        // align-items: center;
        color: #333;
        font-family: Sora;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    // &:hover {
    // background: linear-gradient(338deg, rgba(211, 224, 251, 0.4) 0%, rgba(232, 217, 252, 0.4) 100%);
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    // .ant-btn,
    // .contact-us {
    //   background: linear-gradient(90deg, #8e97ff 0%, #1082ff 100%);
    //   color: #fff;
    //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    // }
    // }
  }
}

.@{products-prefix} {
  &.mobile {
    padding: 0 0 82px;
    background: url('./assets/bg03_mb.png') center bottom no-repeat;
    background-size: 100%;

    .@{products-prefix} {
      &-title {
        font-size: 20px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &-list {
        margin-top: 32px;
        width: 100%;
        display: block;
      }

      &-item {
        padding: 32px;
        height: 366px;
        border-radius: 24px;

        &-title {
          font-size: 16px;
          line-height: 16px;
          column-gap: 6px;

          svg {
            width: 16px;
            height: 16px;
          }
        }

        &.cor-products-item_0,
        &.cor-products-item_1,
        &.cor-products-item_2 {
          &:hover {
            border: 1px solid #e0e2eb;
            background: #fff;
          }

          .ant-btn,
          .contact-us {
            height: 40px;
            padding: 12px 0;
            font-size: 16px;
            line-height: 16px;
            border-radius: 8px;
          }
        }

        &.cor-products-item_1 {
          color: #9999ff;

          .ant-btn {
            background: #9999ff;
          }
        }

        &.cor-products-item_2 {
          color: #33bbff;
        }

        &-price {
          margin: 24px 0 32px;
          font-size: 14px;
          line-height: 16px;
          height: 30px;

          .highlight {
            font-size: 36px;
            line-height: 30px;
          }
        }

        &-desc {
          row-gap: 12px;
          margin-top: 32px;

          dl {
            column-gap: 6px;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }

    .slick-dots {
      display: flex !important;
      column-gap: 8px;
      justify-content: center;
      bottom: -32px;

      li {
        margin: 0;
        width: 8px;
        height: 8px;

        button {
          padding: 0;
          width: 8px;
          height: 8px;

          &::before {
            opacity: 1;
            width: 8px;
            height: 8px;
            content: '';
            background: rgba(31, 44, 71, 0.15);
            border-radius: 99px;
          }
        }

        &.slick-active {
          width: 24px;

          button {
            &::before {
              width: 24px;
              background: #000656;
            }
          }
        }
      }
    }

    .slick-slide {
      padding: 0 16px;

      & > div {
        position: relative;
        padding: 0 5%;

        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 80%;
          position: absolute;
          top: 10%;
          left: 0%;
          border-radius: 20px;
          background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
            linear-gradient(0deg, rgba(238, 240, 246, 0.4), rgba(238, 240, 246, 0.4));
          border: 1px solid #e0e2eb;
          z-index: -99;
        }
      }
    }

    .slick-slide {
      padding: 0 16px;

      & > div {
        position: relative;
        padding: 0 5%;

        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 80%;
          position: absolute;
          top: 10%;
          left: 0%;
          border-radius: 20px;
          background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
            linear-gradient(0deg, rgba(238, 240, 246, 0.4), rgba(238, 240, 246, 0.4));
          border: 1px solid #e0e2eb;
          z-index: -99;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;