.ant-popconfirm {
  .ant-popover-content {
    border-radius: 8px;
    overflow: hidden;
    .ant-popover-inner-content {
      background: #e3efff;
      color: @text-color;
      border-radius: 8px;
      padding: 12px;
      overflow: hidden;
    }
    .ant-popover-buttons {
      display: flex;
      justify-content: flex-end;
    }
    .ant-popover-arrow-content {
      &::before {
        background: #e3efff;
      }
    }
  }
}
