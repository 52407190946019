@import '~@/themes/func.less';
@merchant-detail-prefix: ~'@{cor-prefix}-merchant-detail';
.@{merchant-detail-prefix} {
  &-wrap {
    flex: 1;
    padding-top: 24px;
    display: flex;
    align-items: stretch;
    &-left {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 24px;
      padding-top: 0;
    }
    &-right {
      width: 280px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      border-left: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      row-gap: 16px;
      &-top {
        padding: 24px 8px;
        .ant-btn {
          width: 100%;
        }
      }
      &-bottom {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
    }
  }
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  &.mobile {
    padding: 0px;
    row-gap: 0;
  }
  &-skeleton {
    padding: 0 12px;
    &-item {
      display: flex;
      column-gap: 16px;
      justify-content: flex-start;
      .ant-skeleton {
        margin: 0;
        &:nth-of-type(2) {
          width: 400px;
          border-radius: 0px 16px 16px 16px;
          overflow: hidden;
        }
        .ant-skeleton-title {
          height: 77px;
        }
      }
    }
  }
  .cor-chat-loading {
    width: 40%;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;