@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@knowledge-prefix: ~'@{cor-prefix}-knowledge';

.@{knowledge-prefix} {
  display: flex;
  flex-direction: column;
  row-gap: @space-10x;
  padding: 0 @space-10x;
  width: 100%;
  height: calc(100%);
  position: relative;
  .comment-modal {
    .comment-input {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  
    .send-button {
      margin-top: 20px;
      width: 100%;
    }
  
  }
  &-head {
    display: flex;
    padding-top: @space-10x;
    padding-bottom: @space-4x;
    border-bottom: 1px solid #e7e7ec;
    position: relative;
    &-title {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      margin-right: 42px;
    }
    &-tablist {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 48px;
      font-size: 16px;
      font-weight: 600;
      &-item {
        color: #33333399;
      }
      &-active {
        color: #000656;
      }
    }
    &-btn {
      position: absolute;
      right: 30px;
      top:15px;
      display: flex;
      align-items: center;
      gap: 30px;
      &-enbtn {
        svg {
          width: 20px;
          height: 20px;
        }
        &.ant-btn-primary {
          border: none;
          padding: 8px 10px;
          border-radius: 8px;
          background: var(--Dark, #000656) !important;
          color: var(--red, #fff) !important;;
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 14.52px;
        }
      }
    }
  }

  &-content {
    height: calc(100% - 24px - 78px);
    overflow-y: auto;
    padding: 0 16px;

    .ant-space {
      align-items: start;
      overflow: hidden;
      word-break: break-all;
    }

    .ant-space-item {
      &:nth-of-type(1) {
        width: 440px;
        flex-basis: 440px;
      }

      &:nth-of-type(2) {
        flex: 1;
      }
    }

    .ant-btn.ant-btn-sm {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }

    .ant-card {
      background-color: transparent;

      .ant-card {
        &-head {
          border: 0;
          min-height: auto;
          padding: 0;

          .ant-card-extra {
            padding: 0;
          }

          &-title {
            padding: 0;

            .ant-typography {
              font-family: Inter;
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              color: rgba(51, 51, 51, 1);
            }
          }
        }
      }

      .ant-card {
        &-body {
          margin-top: @space-6x;
          padding: 0px @space-6x;
          border-radius: 16px;
          border: 1px solid rgba(31, 44, 71, 0.07);
          box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
          background-color: #fff;
        }
      }
    }

    .ant-table {
      .ant-table-thead .ant-table-cell {
        background-color: #fff;
        padding-left: 0;
        border-bottom: 1px solid rgba(31, 44, 71, 0.07) !important;
      }

      .ant-table-tbody .ant-table-cell {
        padding: 12px 8px 11px 0;
        height: auto;
        border-bottom: 1px solid rgba(31, 44, 71, 0.07);
      }
    }

    .ant-table-pagination.ant-pagination {
      align-items: center;
      margin: 12px 0;

      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-total-text {
        border-radius: 6px;
        height: 24px;
        min-width: 24px;
        line-height: 22px;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        button {
          border: 0;
        }
      }

      .ant-pagination-total-text {
        flex: 1;
        color: rgba(51, 51, 51, 0.6);
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  &-modal {
    &.ant-modal {
      border-radius: @space-6x;

      .ant-modal-content {
        .ant-modal-body {
          padding: 0 32px 32px;
        }

        .ant-modal-header {
          padding: 36px 70px 36px 24px;
        }

        .ant-modal-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          color: rgba(51, 51, 51, 1);
        }

        .ant-modal-close {
          right: 42px;
          top: 38px;

          .ant-modal-close-x {
            width: 20px;
            height: 20px;
            line-height: 20px;
          }
        }
      }

      .ant-form-item .ant-row {
        row-gap: @space-2x;

        .ant-input {
          border-radius: @space-2x;
        }

        .ant-form-item-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          height: 16px;
          color: rgba(51, 51, 51, 1);

          label {
            &:before {
              display: none;
            }

            &:after {
              display: inline-block;
              margin-right: 4px px;
              color: #ff4d4f;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*';
            }
          }
        }
      }

      .ant-btn.cor-save {
        margin-top: @space-4x;
        padding: 16px 16px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        box-shadow: none;
        border: none;
        height: auto;
        border-radius: @space-3x;
        background: #0080ff;
      }

      .highlight {
        color: var(--red, #f66);
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;