@import '~@/themes/func.less';
@priority-select-prefix: ~'@{cor-prefix}-priority-select';

.@{priority-select-prefix} {
  &.ant-select {
    .ant-select-selector {
      display: flex;
      width: 103px;
      height: 29px;
      padding: 10px 8px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      height: 30px;






      .ant-select-selection-item {
        text-align: left;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        &:before {
          content: "";
          display: inline-block;
          width: 0.7rem;
          height: 0.7rem;
          border-radius: 50%;
          margin-right: 0.6rem;
          background-color: #6f6f6f;
        }
      }


    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }

    &.High,
    &.High.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: #ffeded;
        border: 1px solid rgba(255, 76, 76, 0.1);

        .ant-select-selection-item,
        .ant-select-selector {
          color: #ff4c4c;

          &:before {
            background-color: #ff4c4c;
          }
        }
      }

      .ant-select-arrow {
        svg path {
          fill: #ff4c4c;
        }
      }
    }

    &.Medium,
    &.Medium.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: #fff2d9;

        border: 1px solid rgba(244, 146, 0, 0.1);

        .ant-select-selection-item {
          color: #f49200;

          &:before {
            background-color: #f49200;
          }
        }
      }

      .ant-select-arrow {
        svg path {
          fill: #f49200;
        }
      }
    }

    &.Low,
    &.Low.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: #fffbe3;

        border: 1px solid rgba(239, 186, 0, 0.1);

        .ant-select-selection-item {
          color: #efba00;

          &:before {
            background-color: #efba00;
          }
        }
      }

      .ant-select-arrow {
        svg path {
          fill: #efba00;
        }
      }
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }

  &-popup {
    padding: 0.5rem;
    border-radius: 0.8rem;
    border: 0.1rem solid rgba(31, 44, 71, 0.07);

    .ant-select-item {
      padding: 0.6rem 0.8rem;
      border-radius: 0.8rem;
      font-family: Inter;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.4rem;
      position: relative;
      display: flex;
      align-items: center;
      min-height: initial;
      position: relative;

      &:before {
        content: "";
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        margin-right: 0.6rem;
      }

      &:after {
        content: '';
        display: none;
        width: 9px;
        height: 6px;
        border-width: 0 0 1px 1px;
        border-style: solid;
        transform: rotate(-45deg);
        position: absolute;
        right: 0.9rem;
      }

      &:hover {
        &:after {
          display: inline-block;

        }
      }

      &:not(:first-child) {
        margin-top: 5px;
      }

      // .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      //   background: transparent;
      // }

      &[title='High'],
      &[title='High'].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        &:hover {
          background: rgba(255, 237, 237, 1);

        }

        color: rgba(255, 76, 76, 1);

        &:before {
          background-color: rgba(255, 76, 76, 1);
        }
      }

      &[title='High'].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: rgba(255, 237, 237, 1);

        &:after {
          display: inline-block;

        }

      }

      &[title='Medium'],
      &[title='Medium'].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        &:hover {
          background: rgba(255, 242, 217, 1);

        }

        color: rgba(244, 146, 0, 1);

        &:before {
          background-color: rgba(244, 146, 0, 1);
        }
      }

      &[title='Medium'].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: rgba(255, 242, 217, 1);

        &:after {
          display: inline-block;

        }
      }

      &[title='Low'],
      &[title='Low'].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        &:hover {
          background: rgba(255, 251, 227, 1);

        }

        color: rgba(239, 186, 0, 1);

        &:before {
          background-color: rgba(239, 186, 0, 1);
        }
      }

      &[title='Low'].ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: rgba(255, 251, 227, 1);

        &:after {
          display: inline-block;
        }
      }
    }
  }

}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;