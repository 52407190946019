@import '~@/themes/variable.less';
@import '~@/themes/func.less';
@edit-signature-prefix: ~'@{cor-prefix}-edit-signature';

.@{edit-signature-prefix} {

  .ant-form {
    display: flex;
    flex-direction: column;
    row-gap: @space-4x;

    textarea.ant-input {
      border-radius: @space-3x;
      padding: @space-6x;
      background-color: #fff;
    }

    .ant-btn.cor-save {
      color: #fff;
      margin: 0 auto;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;