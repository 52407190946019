@import '~@/themes/func.less';
@chat-content-prefix: ~'@{cor-prefix}-bot-chat-content';
.@{chat-content-prefix} {
  display: flex;
  padding: 0;
  flex-direction: column;
  row-gap: 24px;
  flex: 1;
  overflow-y: auto;
  height: 100%;
  padding-right: 4px;

  &::-webkit-scrollbar-thumb {
    background: #000656;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;