@import '~@/themes/func.less';
@session-list-prefix: ~'@{cor-prefix}-session-list';
.@{session-list-prefix} {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-search {
    display: flex;
    align-items: center;
    column-gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-input-affix-wrapper {
      width: 100%;
      background: none;
      border: none;
      border-radius: 8px;
      height: 36px;
      padding: 10px;
      display: flex;
      align-items: center;
      border: 2px solid var(--border, rgba(31, 44, 71, 0.07));
      .ant-input-suffix {
        background: none;
        margin-right: 10px;
        svg {
          height: 16px;
          width: 16px;
        }
      }
      input.ant-input {
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        height: 16px;
        background: none;
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
    .ant-btn.ant-btn-primary {
      border-radius: 8px;
      display: inline-flex;
      height: 36px;
      padding: 8px var(--Corner-Radius, 12px) 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      box-shadow: 0px 2px 5px 0px rgba(70, 75, 146, 0.03);
      color: var(--White, #fff);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &-wrap {
    .scroll-bar;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 4px;
  }
  .ant-empty {
    margin-top: 80px;
  }
  &-skeleton {
    display: flex;
    width: 100%;
    flex-direction: column;
    .ant-skeleton {
      margin: 0;
      width: 100%;
      .ant-skeleton-title {
        height: 88px;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;