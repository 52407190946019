@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@team-prefix: ~'@{cor-prefix}-team';
.@{team-prefix} {
  display: flex;
  flex-direction: column;
  row-gap: @space-4x;
  width: 100%;
  height: calc(100%);
  position: relative;
  &-title {
    padding: 24px 0;
    font-size: 24px;
    font-weight: 700;
  }
  &-teamId {
    padding: 12px 0;
    font-size: 18px;
    font-weight: 500;
  }
  &-card {
    &.ant-card {
      background: transparent;
      .ant-card-body {
        margin-top: 24px;
        padding: 0px 24px;
        border-radius: 16px;
        border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
        box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
        background-color: #fff;
      }
      .ant-card-head {
        border: 0;
        min-height: auto;
        padding: 0;
      }
      .ant-table {
        .ant-table-tbody .ant-table-cell {
          padding: 12px 8px 11px 0;
          height: auto;
          border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
        }
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;