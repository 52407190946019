@import '~@/themes/func.less';
@chat-reply-box-prefix: ~'@{cor-prefix}-plugin-reply-box';

.@{chat-reply-box-prefix} {
  border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    textarea {
      width: 100%;
      font-size: 14px;
      padding: 12px 16px;
      border-radius: 6px;
      border: 1px solid var(--text-box-border, #e7e7ec);
      /* text box shadow */
      box-shadow: 0px 4px 4px 0px rgba(211, 213, 237, 0.05) inset;
      background: #fff;
      &:focus,
      &:hover {
        border-radius: 6px;
        border: 2px solid var(--highlighted-text-box, #5c63be);
        /* text box shadow */
        box-shadow: 0px 4px 4px 0px rgba(211, 213, 237, 0.05) inset;
      }

      &.email-content {
        border-radius: 8px 8px 0 0;
      }
      .ant-input {
        padding: 0;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      .switch-box {
        flex: 1;
        display: flex;
        align-items: center;
        column-gap: 4px;
      }

      .btns {
        display: flex;
        column-gap: 12px;
        align-items: center;
      }
    }
    .ant-btn {
      display: inline-flex;
      border-radius: 8px;
      height: 36px;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      width: fit-content;
      svg {
        width: 24px;
        height: 24px;
      }
      &.ant-btn-primary {
        background: var(--Dark, #000656);
        /* button shadow */
        box-shadow: 0px 2px 5px 0px rgba(70, 75, 146, 0.03);
        color: var(--White, #fff);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &.ant-btn-ghost {
        display: inline-flex;
        height: 36px;
        padding: 8px var(--Corner-Radius, 12px) 8px 10px;
        align-items: center;
        gap: 7px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--text-box-border, #e7e7ec);
        background: var(--White, #fff);
        /* button shadow */
        box-shadow: 0px 2px 5px 0px rgba(70, 75, 146, 0.03);

        color: var(--text-1, #333);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .anticon-send {
          height: 24px;
          width: 24px;
        }
      }
      &.send {
        align-self: flex-end;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;