@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@connections-prefix: ~'@{cor-prefix}-connections';

.@{connections-prefix} {
  display: flex;
  row-gap: @space-6x;
  flex-direction: column;
  width: 100%;

  &-card {
    display: flex;
    flex-direction: column;
    row-gap: @space-6x;

    &-head {
      display: flex;
      flex-direction: column;
      row-gap: @space-2x;
    }

    &-title {
      display: flex;
      align-items: center;
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      height: 32px;
      color: rgba(51, 51, 51, 1);
      column-gap: @space-2x;
    }

    &-des {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(51, 51, 51, 0.6);
    }

    &-item {
      display: flex;
      height: 88px;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;

      div {
        display: flex;
        column-gap: @space-4x;
        align-items: center;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }

      &:not(:first-child) {
        border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      }

      .ant-btn {
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        color: rgba(51, 51, 51, 1);
        height: 40px;
        padding: 11px 16px;
        border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
        box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);

        &.cor-connections-card-delete-btn {
          background-color: #000656;
          border: 1px solid transparent;
          box-shadow: none;
          color: #fff;
          &:hover {
            background-color: #000656;
            border: 1px solid transparent;
            color: #fff;
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        &-divider {
          width: 1px;
          background: #d9d9d9;
          height: 24px;
        }
      }
    }
    &-items {
      padding: 0;
      background: #ffffff;
      border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
      border-radius: 16px;
      .ant-skeleton {
        padding: 24px;
      }
    }
  }

  &-signature {
    width: 50%;
  }

  &-card-signature {
    padding: @space-8x;
    border-radius: 16px;
    border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    background-color: rgba(248, 249, 252, 1);
    display: flex;
    flex-direction: column;
    row-gap: @space-4x;

    .cor-text-display {
      background-color: #fff;
      margin-bottom: 0;
    }

    .ant-empty {
      border: 1px dashed rgba(51, 51, 51, 0.2);
      border-radius: @space-4x;
      padding: @space-10x;
      margin: 0;
    }

    .cor-text-display,
    .ant-empty,
    textarea.ant-input {
      min-height: 216px;
    }

    .ant-empty-description {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(51, 51, 51, 0.6);
    }

    .ant-btn {
      padding: 15px;
      height: 56px;
      border-radius: 12px;

      &.white-btn {
        box-shadow: 0px 2px 4px 0px rgba(31, 44, 71, 0.05);
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
        color: rgba(51, 51, 51, 1);
      }

      &.ant-btn-primary {
        color: #fff;
        border: 1px solid rgba(0, 128, 255, 1);
        background: rgba(0, 128, 255, 1);
      }
    }
  }

  &-section {
    &-title {
      .font24;
      font-weight: 700;
      display: flex;
      justify-content: space-between;

      &-right {
        display: flex;
        column-gap: 24px;
        align-items: center;
      }
    }
  }

  &-header {
    .font24;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    &-right {
      display: flex;
      column-gap: 24px;
      align-items: center;
    }

    &-authorize {
      display: flex;
      align-items: center;
    }
  }

  &-actions {
    display: flex;
    column-gap: @space-4x;
    justify-content: flex-end;
  }

  &-modal {
    &-form {
      display: flex;
      flex-direction: column;
      row-gap: @space-4x;
      align-items: stretch;

      &-section {
        display: flex;
        column-gap: @space-4x;
      }

      &-save.ant-btn.ant-btn-primary {
        margin-top: 40px;
        border-radius: 8px;
        background: var(--btn, linear-gradient(180deg, #4d4d4d 0%, #333 100%));
        display: flex;
        justify-content: center;
        .font20;
        font-weight: 700;
        color: var(--white, #fff);
        height: 64px;
        width: 100%;
      }
    }
  }
  &-divider {
    border-bottom: 1px solid var(--border, #d9d9d9);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;