@import '~@/themes/func.less';
@merchants-prefix: ~'@{cor-prefix}-merchants';
.@{merchants-prefix} {
  flex: 1;
  overflow: auto;
  &-wrap {
    display: flex;
    flex-direction: column;
    height: var(--doc-height);
    &-log {
      padding: 28px 32px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    column-gap: 24px;
    img {
      height: 24px;
    }
    display: flex;
    padding: 24px;
    gap: 16px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--border, #000656);
    position: relative;

    &-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: #333;
    }
    &:hover {
      background: linear-gradient(90deg, rgba(153, 153, 255, 0.05) 0%, rgba(0, 128, 255, 0.05) 100%);
    }

    &::before {
      content: '';
      height: 100%;
      width: 4px;
      position: absolute;
      right: 0px;
      top: 0px;
      background-color: #000656;
      transition: all 0.2s linear;
      transform: scale(0);
    }
    &.selected {
      &::before {
        transform: scale(1);
      }
      border-bottom: 1px solid var(--border, #000656);
      background: var(--hover, linear-gradient(90deg, rgba(153, 153, 255, 0.05) 0%, rgba(0, 128, 255, 0.05) 100%));
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;