@import '~@/themes/func.less';
@limited-prefix: ~'@{cor-prefix}-limited';
.@{limited-prefix} {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: linear-gradient(180deg, #f5f5fb 9.31%, rgba(254, 254, 255, 0) 100%);
  margin: 0 auto;
  row-gap: 8px;
  position: relative;
  &-top {
    height: 124px;
    backdrop-filter: blur(15px);
    fill: linear-gradient(180deg, rgba(10, 135, 255, 0.28) 0%, rgba(168, 147, 255, 0.3) 87.4%);
    &-inner {
      align-items: stretch;
      width: 1240px;
      margin: 0 auto;
      display: flex;
      position: relative;
      flex-direction: column;
    }
  }
  &-bottom {
    width: 840px;
    margin: 0 auto;
    position: relative;
    display: flex;
    font-size: 30px;
    line-height: 40px;
    row-gap: 40px;
    flex-direction: column;
  }

  &.mobile {
    .@{limited-prefix} {
      &-top {
        height: 64px;
        &-inner {
          width: 100%;
        }
      }
      &-bottom {
        width: 100%;
        padding: 0 20px;
        font-size: 18px;
        line-height: 32px;
        row-gap: 24px;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;