@import '@/themes/index.less';
* {
  box-sizing: border-box;
  &:before {
    box-sizing: border-box;
  }
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  .font14;
  .font(Inter);
  scroll-behavior: smooth;
  ::selection {
    // background: linear-gradient(93deg, rgba(153, 153, 255, 0.2) 0%, rgba(0, 128, 255, 0.2) 100%);
    // backdrop-filter: blur(1.1746575832366943px);
    color: #fff;
  }
}

ul {
  list-style-type: none;
  margin: 0;
}

html {
  &.mobile {
    font-size: 12px;
  }
}
.App {
  height: 100vh;
  width: 100%;
  &-fallback {
    display: flex;
    justify-content: center;
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;