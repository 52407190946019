@import '~@/themes/func.less';
@playground-chat-item-prefix: ~'@{cor-prefix}-playground-chat-item';
.@{playground-chat-item-prefix} {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  &-top {
    display: flex;
    column-gap: 16px;
    width: 100%;
  }
  &.customer {
    justify-content: flex-end;
    align-items: flex-end;
    .cor-link-preview-top {
      padding-right: 40px;
    }

    .ant-avatar {
      order: 2;
    }

    .@{playground-chat-item-prefix} {
      &-content {
        order: 1;
        align-items: flex-end;

        .date-and-option {
          justify-content: space-between;
          &-left {
            order: 1;
          }
        }
        &-top {
          max-width: 100%;
          border-radius: 16px 0px 16px 16px;
          background: linear-gradient(93deg, rgba(153, 153, 255, 0.2) 0%, rgba(0, 128, 255, 0.2) 100%);
        }
      }

      &-timestamp {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }
    &.mobile {
      .@{playground-chat-item-prefix} {
        &-content {
          &-top {
            border-radius: 8px 0px 8px 8px;
          }
        }
      }
    }
  }
  &.assitant {
    padding-right: 40px;
  }
  &.mobile {
    padding: 12px;
    row-gap: 4px;
    &-top {
      column-gap: 8px;
    }
    .@{playground-chat-item-prefix} {
      &-content {
        &-top {
          border-radius: 0px 8px 8px 8px;
          padding: 8px 10px;
          row-gap: 4px;
        }
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    row-gap: @space;
    order: 2;
    overflow: auto;
    align-items: flex-start;
    flex: 1;
    &-top {
      max-width: 100%;
      border-radius: 0px 16px 16px 16px;
      background: rgba(0, 51, 102, 0.05);
      padding: 16px 20px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    .ant-btn {
      padding: 0 8px;
    }
  }
  &-text {
    max-width: 100%;
    color: #333;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &-subject {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .subject-1 {
        border-radius: 8px;
        background: linear-gradient(138deg, #0080ff -20.61%, #99f 72.63%);
        width: 62px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
      }
      .subject-2 {
        color: #333;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin-left: 10px;
      }
    }

    p:empty {
      display: none;
    }

    pre {
      white-space: pre-wrap;
      overflow-x: auto;
      overflow-y: hidden;
      word-wrap: break-word;
      margin-bottom: 0;
    }

    .ant-btn.ant-btn-link {
      padding: 0;
      color: #1082ff;
      float: right;
    }
  }

  &-html {
    transition: all ease-in-out 0.3s;

    &.overflow {
      position: relative;
      max-height: 6.5em;
      overflow: hidden;
    }
  }

  &-btns {
    display: flex;
    column-gap: @space-2x;
    align-items: center;

    .ant-btn {
      padding: 0;
      height: 26px;
      color: #1082ff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-timestamp {
    width: 100%;
    max-width: unset;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    &-name {
      white-space: nowrap;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    column-gap: 8px;
    .date-and-option {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        display: flex;
        align-items: center;
        font-family: Inter;
        font-size: 14px;
        line-height: 16px;

        .name {
          color: #333;
          font-weight: 700;
        }

        .date {
          margin-right: 10px;
          margin-left: 10px;
          color: rgba(51, 51, 51, 0.6);
        }
      }

      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .collapse,
        .expand {
          display: flex;
          align-items: center;
          padding: 0px;
          height: 16px;
          transition: all ease-in-out 0.3s;

          span + .anticon {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
  &-examples {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: flex-end;
    &-item {
      background: #0080ff;
      border-radius: 8px;
      padding: 8px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      white-space: wrap;
      &.disabled {
        cursor: not-allowed;
        background: rgba(0, 51, 102, 0.05);
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;