@import '~@/themes/func.less';
@edit-member-prefix: ~'@{cor-prefix}-edit-member';
.@{edit-member-prefix} {
  &-doc-modal {
    .ant-modal-content {
      gap: 24px;
      display: flex;
      flex-direction: column;
    }
    &-save {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &-btn {
        width: 150px;
        background-color: #eaeaea;
        color: #000;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;