@import '~@/themes/func.less';
@testimony-prefix: ~'@{cor-prefix}-testimony';

.@{testimony-prefix} {
  margin: auto;
  margin-top: 80px;
  width: 1248px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    color: var(--Dark, #000656);
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 10px;
    b {
      font-weight: 600;
    }
  }
  &-subtitle {
    color: var(--Dark, #000656);
    text-align: center;
    font-family: Sora;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    letter-spacing: 0.09px;
  }
  &-btn {
    margin-top: 48px;
    display: flex;
    height: 44px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    gap: 10.471px;
    color: var(--Background, #fcfffc);
    background: var(--Dark, #000656);
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    font-family: sora;
    text-transform: capitalize;
    cursor: pointer;
    transition: transform 0.2s;
  }
  &-btn:hover {
    transform: scale(1.1);
  }
  &-container {
    width: 100%;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    &-center {
      position: absolute;
      &-img1 {
        position: relative;
        left: 450px;
        top: 0;
        width: 448px;
        height: 518px;
      }
      &-img2 {
        position: relative;
        left: 440px;
        width: 460px;
        height: 518px;
      }
      &-img3 {
        position: relative;
        left: 410px;
        width: 497px;
        height: 518px;
      }
      &-img4 {
        position: relative;
        left: 390px;
        width: 510px;
        height: 518px;
      }
    }
    &-right {
      padding: 24px;
      display: flex;
      flex-direction: column;
      &-title {
        width: 310px;
        font-size: 16px;
        font-weight: 400;
        line-height: 125%; /* 20px */
        letter-spacing: 0.08px;
        text-align: center;
        b {
          font-weight: 600;
        }
      }
      &-img {
        margin-top: 32px;
        width: 310px;
        height: 460px;
      }
    }
    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      &-1 {
        width: 480px;
        height: 120px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        &-btn {
          z-index: 1;
          width: 161px;
          height: 29px;
          box-shadow: 0px 4px 4px 0px #00000040;
          border-top: 2px solid #000656;
          border-left: 2px solid #000656;
          border-bottom: 3px solid #000656;
          border-right: 3px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 10px;
          z-index: 2;
          width: 380px;
          color: #000656;
          text-align: center;
          font-family: Sora;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          letter-spacing: 0.07px;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 480px;
          height: 0;
          border-top: 120px solid #f4f4f4;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
        }
      }
      .active1 {
        .cor-testimony-container-left-1-yuan {
          border-top: 120px solid #effef9;
        }
        .cor-testimony-container-left-1-btn {
          background-color: #c7fff9;
        }
      }
      &-2 {
        width: 397px;
        height: 120px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        &-btn {
          z-index: 1;
          width: 161px;
          height: 29px;
          box-shadow: 0px 4px 4px 0px #00000040;
          border-top: 2px solid #000656;
          border-left: 2px solid #000656;
          border-bottom: 3px solid #000656;
          border-right: 3px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 10px;
          z-index: 2;
          width: 340px;
          color: #000656;
          text-align: center;
          font-family: Sora;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          letter-spacing: 0.07px;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 390px;
          height: 0;
          border-top: 120px solid #f4f4f4;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
        }
      }
      .active2 {
        .cor-testimony-container-left-2-yuan {
          border-top: 120px solid #fffeec;
        }
        .cor-testimony-container-left-2-btn {
          background-color: #f6ee36;
        }
      }
      &-3 {
        width: 310px;
        height: 120px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        &-btn {
          z-index: 1;
          width: 133px;
          height: 29px;
          box-shadow: 0px 4px 4px 0px #00000040;
          border-top: 2px solid #000656;
          border-left: 2px solid #000656;
          border-bottom: 3px solid #000656;
          border-right: 3px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 10px;
          z-index: 2;
          width: 220px;
          color: #000656;
          text-align: center;
          font-family: Sora;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          letter-spacing: 0.07px;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 310px;
          height: 0;
          border-top: 120px solid #f4f4f4;
          border-left: 40px solid transparent;
          border-right: 40px solid transparent;
        }
      }
      .active3 {
        .cor-testimony-container-left-3-yuan {
          border-top: 120px solid #ffeaf6;
        }
        .cor-testimony-container-left-3-btn {
          background-color: #fdb7e1;
        }
      }
      &-4 {
        width: 230px;
        height: 154px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        &-btn {
          z-index: 1;
          width: 100px;
          height: 29px;
          box-shadow: 0px 4px 4px 0px #00000040;
          border-top: 2px solid #000656;
          border-left: 2px solid #000656;
          border-bottom: 3px solid #000656;
          border-right: 3px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 10px;
          z-index: 2;
          width: 150px;
          color: #000656;
          text-align: center;
          font-family: Sora;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          letter-spacing: 0.07px;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 230px;
          height: 0;
          border-top: 154px solid #f4f4f4;
          border-left: 50px solid transparent;
          border-right: 50px solid transparent;
        }
      }
      .active4 {
        .cor-testimony-container-left-4-yuan {
          border-top: 154px solid #f1f3f9;
        }
        .cor-testimony-container-left-4-btn {
          background-color: #e4cfff;
        }
      }
    }
  }
  .acbtn {
    cursor: pointer;
    transition: transform 0.2s;
    position: relative;
    font-weight: 600;
    &-right {
      height: 6px;
      width: 6px;
      position: absolute;
      top: -3px;
      right: -3px;
      background-color: #f4f4f4;
      div {
        height: 3px;
        width: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #000656;
      }
    }
  }
  .acbtn:hover {
    transform: scale(1.1);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;