@import '~@/themes/func.less';
@documentsTabs-prefix: ~'@{cor-prefix}-documentsTabs';
.@{documentsTabs-prefix} {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 40px;
  &-left {
    display: flex;
    align-items: center;
    gap: 20px;
    &-item {
      padding: 8px 10px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #33333399;
      cursor: pointer;
      &.active {
        border-radius: 20px;
        background: linear-gradient(247.43deg, #E8EAFF 26.95%, #F6D2F9 144.89%);
        color: #000656;
        
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;