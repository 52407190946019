@import '~@/themes/func.less';
@chat-loading-prefix: ~'@{cor-prefix}-chat-loading';
.@{chat-loading-prefix} {
  display: flex;
  column-gap: 16px;
  width: 100%;
  padding: 0 16px;
  justify-content: flex-start;
  .loading-dots-animation {
    padding-left: 25px;
  }
  .loading-dots-animation {
    max-width: 100%;
    border-radius: 16px 0px 16px 16px;
    padding: 16px;
    display: inline-flex;
    background: linear-gradient(93deg, rgba(153, 153, 255, 0.2) 0%, rgba(0, 128, 255, 0.2) 100%);
    .loading-dot {
      background: linear-gradient(90deg, #9498ff 0%, #0981ff 100%);
      height: 7px;
      width: 7px;
      border-radius: 50%;
      margin: 0 3px;
      opacity: 0.7;
      animation: MakeLoadingAnimation 1.5s ease-in-out infinite;
      &:first-of-type {
        animation-delay: 0.2s;
      }
      &:nth-of-type(2) {
        animation-delay: 0.3s;
      }
      &:last-of-type {
        animation-delay: 0.4s;
      }
    }
  }
  @keyframes MakeLoadingAnimation {
    0%,
    44% {
      transform: translate(0px);
    }
    22% {
      opacity: 0.4;
      transform: translateY(-6px);
    }
    44% {
      opacity: 0.2;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;