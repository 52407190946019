body {
  .ant-input {
    border-radius: 4px;
    border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    background: var(--bg, #f8f9fc);
    display: flex;
    padding: 16px 20px;
    gap: 12px;
    .font14;
    &:hover {
      border-color: var(--p-1, #000656);
    }
  }
  .ant-input-affix-wrapper {
    line-height: normal;
    padding: 0;
    .ant-input:not(textarea) {
      padding: 16px 20px;
    }
    .ant-input-suffix {
      background: var(--bg, #f8f9fc);
      margin: 0;
    }
  }
}
