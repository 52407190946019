@import '~@/themes/func.less';
@weekly-report-prefix: ~'@{cor-prefix}-weekly-report';
.@{weekly-report-prefix} {
  overflow: hidden;
  padding: 32px;
  display: flex;
  padding-bottom: 0;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  &-title {
    color: var(--text-1, #333);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  &-container {
    flex: 1;
    display: flex;
    overflow: auto;
    flex-direction: column;
    row-gap: 24px;
    &.empty {
      justify-content: center;
    }
  }
  &-section {
    .wmde-markdown {
      overflow: auto;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;