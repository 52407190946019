@import '~@/themes/func.less';
@a-iComment-reply-prefix: ~'@{cor-prefix}-a-iComment-reply';
.@{a-iComment-reply-prefix} {
  display: flex;
  column-gap: 8px;
  flex-direction: column;
  &-content {
    display: flex;
    column-gap: 8px;
    align-items: center;
    .ant-input {
      border: none;
      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #66f;
      }
    }
    .ant-btn {
      padding: 0 4px;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;