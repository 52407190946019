@import '~@/themes/func.less';
@stats-prefix: ~'@{cor-prefix}-stats';
.@{stats-prefix} {
  padding: 32px;
  padding-bottom: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 24px;
  &-title {
    color: var(--text-1, #333);
    font-family: Inter;
    font-size: 32px;
    line-height: 32px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    column-gap: 8px;
    .date {
      color: var(--p1, #000656);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      border-radius: 12px;
      background: rgba(0, 128, 255, 0.1);
      padding: 4px 12px;
    }
  }
  &-container {
    flex: 1;
    display: flex;
    overflow: auto;
    flex-direction: column;
    row-gap: 24px;
    &-row {
      justify-content: center;
      column-gap: 24px;
      display: flex;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 48px;
        align-items: center;
        flex: 1;
        row-gap: 32px;
        transition: transform 0.3s ease;
        border: 1px solid var(--p1, #000656);
        border-radius: 32px;
        cursor: pointer;
        border: 1px solid #e0e2eb;
        background-image: linear-gradient(239deg, rgba(238, 240, 246, 0.4) 0%, rgba(238, 240, 246, 0) 100%);
        background-repeat: no-repeat;
        background-size: 300px 200px, cover;
        background-position: 100% 0;
        &-number {
          color: var(--text-1, #333);
          font-family: Inter;
          font-size: 48px;
          font-style: normal;
          line-height: 52px;
          font-weight: 700;
        }
        &-title {
          color: var(--p1, #000656);
          font-family: Inter;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;