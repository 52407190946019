.App,
.ant-drawer,
.ant-tooltip {
  .ant-tag {
    border-radius: 8px;
    align-items: center;
    display: flex;
    height: 20px;
  }
}
