@import '~@/themes/func.less';
@mobileHomeClients-prefix: ~'@{cor-prefix}-mobileHomeClients';

.@{mobileHomeClients-prefix} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 16px;
  &-title {
    color: var(--Dark, #000656);
    text-align: center;
    font-family: Sora;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  &-tabs {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    overflow: auto;
    scrollbar-width: none; /* 隐藏滚动条 (适用于Firefox) */
    -ms-overflow-style: none; /* 隐藏滚动条 (适用于IE和Edge) */
    &-img {
      width: 100px;
      height: 32px;
    }
  }
  &.tabs::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 (适用于Webkit浏览器，如Chrome, Safari) */
  }
  &-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 16px;
    background-color: #000656;
    &-left {
      &-title {
        font-family: Sora;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        color: #fdfef5;
        line-height: 125%; /* 22.5px */
        letter-spacing: -0.09px;
        &:nth-of-type(2) {
          margin-top: 24px;
        }
        &.big {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%;
          .highlight {
            color: #febeff;
          }
        }
      }

      &-title3 {
        margin-top: 24px;
        color: #9499d6;
        font-family: Sora;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 20.4px */
        letter-spacing: 0.085px;
      }
    }
    &-right {
      width: 100%;
      height: 277px;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;