@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@cor-ace-editor-prefix: ~'@{cor-prefix}-cor-ace-editor';
.@{cor-ace-editor-prefix} {
  .ace_editor {
    width: 100% !important;
    border-radius: @space-2x;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;