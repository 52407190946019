@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@header-drop-down-prefix: ~'@{cor-prefix}-header-drop-down';
.@{header-drop-down-prefix} {
  display: flex;
  align-items: center;
  .font14;
  column-gap: @space;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 400;
  cursor: pointer;
  &-name {
    font-weight: 800;
    color: #333;
  }

  &-render {
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    background: #ffffff;
    border: 1px solid @border-color;
    &-item {
      padding: 4px;
      border-radius: 4px;
      &.name {
        font-size: 16px;
        line-height: normal;
        font-weight: 600;
      }
      &.title {
        font-size: 14px;
        line-height: normal;
        font-weight: 600;
      }
      cursor: pointer;
      &:hover {
        background: #e3efff;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;