@import '~@/themes/variable.less';
.login {
  background: var(--bg, #f8f9fc);
  height: 100%;
  display: flex;
  padding-top: 120px;
  justify-content: center;
  align-items: flex-start;
  &-content {
    width: 480px;
    border-radius: @space-8x;
    padding-bottom: 64px;
    border-radius: 32px;
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;

    align-items: center;

    &-logo {
      background: url('./assets/bg_login_top.png') top no-repeat;
      display: flex;
      flex-direction: column;
      background-size: 80%;
      row-gap: @space-6x;
      width: 100%;
      padding-top: 64px;
      align-items: center;
      &-top {
        height: 80px;
      }
      &-bottom {
        height: 14px;
      }
      padding-bottom: 64px;
    }
    &-btn {
      display: flex;
      height: 64px;
      padding: @space-5x 0px;
      justify-content: center;
      border-radius: @space-4x;
      align-items: center;
      width: 100%;
      .font20;
      color: #fff;
      background: linear-gradient(180deg, #4d4d4d 0%, #333 100%);
    }
    &-bottom {
      padding: 0 64px;
      width: 100%;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;