@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@aigc-bot-prefix: ~'@{cor-prefix}-aigc-bot';
.@{aigc-bot-prefix} {
  position: absolute;
  right: 10px;
  top: 150px;
  z-index: 100000000000;
  border-radius: 12px;
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  align-items: stretch;
  &.mobile {
    top: 50px;
  }
  &-tooltip {
    .ant-tooltip-inner {
      font-size: 16px;
      line-height: normal;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  }

  &.expand {
    overflow: hidden;
    background: #fff;
    padding: 20px;
    top: unset;
    bottom: 40px;
    max-height: calc(var(--doc-height) - 60px) !important ;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  }
  &-header {
    cursor: pointer;
    display: flex;
    padding-bottom: 8px;
    width: 100%;
    column-gap: 12px;
    justify-content: space-between;
    border-bottom: 1px solid @border-color;
    &-left {
      display: flex;
      align-items: baseline;
      column-gap: 4px;
      flex: 1;
      overflow: hidden;
      line-height: 24px;
      .highlight {
        font-weight: 600;
        font-size: 16px;
        display: block;
        flex: 1;
        line-height: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-height: 48px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-right {
      width: 20px;
      height: 20px;
    }
  }
  &-list {
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  &-input {
    border-radius: 8px;
    background: #f6f6f6;
    padding: 8px;
  }
  &.mobile {
    right: 8px;
    bottom: 56px;
    z-index: 1000000;
    position: fixed;
    max-width: var(--doc-width);
  }
  &-ai {
    display: flex;
    align-items: center;
    column-gap: 10px;
    align-self: flex-end;
    &-text {
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.8);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #fff;
      column-gap: 15px;
      position: relative;
      &::after {
        position: absolute;
        right: -9px;
        top: 10px;
        content: '';
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 10px solid rgba(0, 0, 0, 0.8);
        border-bottom: 7px solid transparent;
      }
    }
  }
}
.ailog {
  cursor: pointer;
  &.mobile {
    height: 31px;
    width: 31px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;