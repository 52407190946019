@import '~@/themes/func.less';
@documents-prefix: ~'@{cor-prefix}-documents';
.@{documents-prefix} {
  height: calc(100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-center {
    width: 100%;
    display: flex;
    &-left {
      overflow: auto;
      height: calc(100vh - 150px);
      width: 320px;
      padding-right: 45px;
      border-right: 1px solid #cccccc;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;