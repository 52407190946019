@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@sider-left-prefix: ~'@{cor-prefix}-sider-left';

.@{sider-left-prefix} {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 224px;
  font-family: sora;
  border-right: 1px solid var(--border, rgba(31, 44, 71, 0.07));
  &-inner {
    background: #fff;
    box-sizing: border-box;
    flex: 1;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-headMsg {
      position: fixed;
      bottom: 0;
      left: 0;
      border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    }

    &-section {
      display: flex;
      align-items: center;
      flex-direction: column;

      width: 100%;
      &-log {
        padding: 28px 32px;
        width: 100%;
        &-img {
          width: 151px;
        }
      }
    }
    &-item {
      margin-bottom: 16px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      width: 192px;
      height: 48px;
      box-sizing: border-box;
      column-gap: 12px;
      span {
        display: flex;
        align-items: center;
      }

      &-text1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: var(--text-2, rgba(51, 51, 51, 0.6));
      }

      &-text2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: var(--text-2, rgba(51, 51, 51, 0.6));
      }

      .icon {
        path {
          fill: var(--text-2, rgba(51, 51, 51, 0.6));
        }
      }

      &:hover:not(.active) {
      }

      &.active {
        .cor-sider-left-inner-item-text2 {
          color: var(--Dark, #000656);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .icon {
          path {
            fill: #000656;
            fill-opacity: 1;
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;