@import '~@/themes/func.less';
@homeClients-prefix: ~'@{cor-prefix}-homeClients';

.@{homeClients-prefix} {
  width: 1248px;
  margin: 0 auto;
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    color: #000656;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    .highlight {
      font-weight: 600;
    }
  }
  &-tabs {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &-img {
      width: 200px;
      height: 64px;
    }
  }
  &-container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
    padding: 0 80px;
    background-color: #000656;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-title {
        color: #fdfef5;
        font-family: Sora;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 22.5px */
        letter-spacing: -0.09px;
        &:nth-last-of-type(2) {
          margin-top: 24px;
        }
        &.big {
          margin-top: 24px;
          color: #fdfef5;
          leading-trim: both;
          text-edge: cap;
          font-family: Sora;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%;
          .highlight {
            color: #fdb7e1;
          }
        }
      }

      &-title3 {
        margin-top: 32px;
        color: #9499d6;
        font-family: Sora;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 21.6px */
        letter-spacing: -0.09px;
      }
    }
    &-left {
      height: 452px;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;