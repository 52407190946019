@import '~@/themes/func.less';
@documentsSidebar-prefix: ~'@{cor-prefix}-documentsSidebar';
.@{documentsSidebar-prefix} {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  background: var(--White, #ffffff);
  // border: 2px solid rgba(31, 44, 71, 0.07);
  // border-radius: 12px;
  &-item {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* 添加过渡效果 */
    color: #777777;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    
    .delete-icon {
      position: absolute;
      right: 10px;
      display: none;
      color: #999;
      &:hover {
        color: #f5222d;
      }
    }
    &:hover {
      background-color: #f0f0f0; /* 浅色背景 */
      color: #000000;
      .delete-icon {
        display: inline-block;
      }
    }
    
    &.active{
      background-color: #f0f0f0; /* 浅色背景 */
      color: #000000;
    }
  }
}
.deletetags-confirm-modal {
  .ant-modal-content {
    .ant-modal-body{
      padding-top: 20px !important;
    }
    .ant-modal-footer {
      border-top: none !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
}
@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;