@import '~@/themes/func.less';
@product-modal-prefix: ~'@{cor-prefix}-product-modal';
.@{product-modal-prefix}.ant-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px;
      .cor-products {
        row-gap: 32px;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;