@import '~@/themes/func.less';
@inbox-gmail-prefix: ~'@{cor-prefix}-inbox-mail';
.@{inbox-gmail-prefix} {
  &-chat {
    display: flex;
    flex: 1;
    height: calc(100% - 156px);

    &-left {
      border-right: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      width: 400px;
      overflow: hidden;
      padding: 20px 18px;
      padding-bottom: 0px;
      padding-left: 8px;
      display: flex;
      flex-direction: column;
      position: relative;
      row-gap: 18px;
      &-resizer {
        width: 6px;
        height: 100%;
        background-color: #ccc;
        cursor: ew-resize;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &-resizer.visible {
        opacity: 1;
      }
      &-header {
        display: flex;
        gap: 16px;
        flex-direction: column;
        &-bottom {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .ant-btn.ant-btn-primary {
          border-radius: 8px;
          display: inline-flex;
          height: 36px;
          padding: 8px var(--Corner-Radius, 12px) 8px 10px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          box-shadow: 0px 2px 5px 0px rgba(70, 75, 146, 0.03);
          color: var(--White, #fff);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &-search {
        width: 400px;
        padding: 0 24px;
      }
    }

    &-right {
      flex: 1;
      display: flex;
      padding: 0px 42px 0px 34px;
      flex-direction: column;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;