@import '~@/themes/func.less';
@mobileTestimony-prefix: ~'@{cor-prefix}-mobileTestimony';

.@{mobileTestimony-prefix} {
  margin: auto;
  width: 100%;
  &-top {
    display: flex;
    padding: 40px 16px 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    color: var(--Grey-Body-Text, #4c4f71);
  }
  &-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: var(--Dark, #000656);
  }
  &-subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    text-align: center;
  }
  &-btn {
    border-radius: 21.106px;
    width: fit-content;
    background: var(--Dark, #000656);
    cursor: pointer;
    display: flex;
    margin: 0 auto;
    margin-top: 40px;
    padding: 7.739px 16.884px;
    align-items: center;
    gap: 7.035px;
    color: var(--Background, #fcfffc);
    text-align: center;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    transition: transform 0.2s;
  }

  &-container {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    &-center {
      &-img1 {
        width: 205px;
        height: 210px;
      }
      &-img2 {
        width: 226px;
        height: 210px;
      }
      &-img3 {
        width: 240px;
        height: 328px;
      }
      &-img4 {
        width: 258px;
        height: 446px;
      }
    }
    &-right {
      padding: 24px;
      width: 232px;
      display: flex;
      align-items: center;
      flex-direction: column;
      &-title {
        width: 205px;
        color: var(--Dark, #000656);
        text-align: center;
        font-family: Sora;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
      }
      &-img {
        margin-top: 16px;
        width: 180px;
        height: 220px;
      }
    }
    &-right2 {
      position: absolute;
      top: 210px;
    }
    &-left {
      display: flex;
      flex-direction: column;
      gap: 18px;
      &-1 {
        width: 161px;
        height: 124px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 12px;
        &-btn {
          z-index: 1;
          width: 94px;
          height: 29px;
          box-shadow: 0px 2px 2px 0px #00000040;
          border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-left: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-bottom: 1.5px solid #000656;
          border-right: 1.5px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 12px;
          z-index: 2;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 161px;
          height: 0;
          border-top: 124px solid #f4f4f4;
          border-right: 20px solid transparent;
        }
      }
      .active1 {
        .cor-mobileTestimony-container-left-1-yuan {
          border-top: 124px solid #effef9;
        }
        .cor-mobileTestimony-container-left-1-btn {
          background-color: #c7fff9;
        }
      }
      &-2 {
        width: 143px;
        height: 124px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 12px;
        &-btn {
          z-index: 1;
          width: 111px;
          height: 29px;
          box-shadow: 0px 2px 2px 0px #00000040;
          border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-left: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-bottom: 1.5px solid #000656;
          border-right: 1.5px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 12px;
          z-index: 2;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 143px;
          height: 0;
          border-top: 124px solid #f4f4f4;
          border-right: 20px solid transparent;
        }
      }
      .active2 {
        .cor-mobileTestimony-container-left-2-yuan {
          border-top: 124px solid #fffeec;
        }
        .cor-mobileTestimony-container-left-2-btn {
          background-color: #f6ee36;
        }
      }
      &-3 {
        width: 131px;
        height: 124px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 12px;
        &-btn {
          z-index: 1;
          width: 111px;
          height: 29px;
          box-shadow: 0px 2px 2px 0px #00000040;
          border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-left: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-bottom: 1.5px solid #000656;
          border-right: 1.5px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-text {
          margin-top: 12px;
          z-index: 2;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 131px;
          height: 0;
          border-top: 124px solid #f4f4f4;
          border-right: 20px solid transparent;
        }
      }
      .active3 {
        .cor-mobileTestimony-container-left-3-yuan {
          border-top: 120px solid #ffeaf6;
        }
        .cor-mobileTestimony-container-left-3-btn {
          background-color: #fdb7e1;
        }
      }
      &-4 {
        width: 117px;
        height: 124px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 12px;
        &-btn {
          z-index: 1;
          width: 70px;
          height: 29px;
          box-shadow: 0px 2px 2px 0px #00000040;
          border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-left: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          border-bottom: 1.5px solid #000656;
          border-right: 1.5px solid #000656;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text {
          margin-top: 12px;
          z-index: 2;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: center;
        }
        &-yuan {
          position: absolute;
          top: 0;
          left: 0;
          width: 117px;
          height: 0;
          border-top: 124px solid #f4f4f4;
          border-right: 20px solid transparent;
        }
      }
      .active4 {
        .cor-mobileTestimony-container-left-4-yuan {
          border-top: 154px solid #f1f3f9;
        }
        .cor-mobileTestimony-container-left-4-btn {
          background-color: #e4cfff;
        }
      }
    }
  }
  .acbtn {
    cursor: pointer;
    transition: transform 0.2s;
    position: relative;
    &-right {
      height: 4px;
      width: 4px;
      position: absolute;
      top: -2px;
      right: -2px;
      background-color: #f4f4f4;
      div {
        height: 2px;
        width: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #000656;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;