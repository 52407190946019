@import '~@/themes/func.less';
@assignee-prefix: ~'@{cor-prefix}-assignee';
.@{assignee-prefix} {
  &.ant-select {
    width: 80%;
    border: none;
    .ant-select-selector {
      border: none;
      background: none;
      .ant-select-selection-placeholder {
        text-align: left;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;