@import '~@/themes/func.less';
@mobilebanner-prefix: ~'@{cor-prefix}-mobilebanner';

.@{mobilebanner-prefix} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  &-title {
    width: 343px;
    color: var(--Dark, #000656);
    text-align: center;
    font-family: Sora;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    z-index: 1;
    .highlight {
      color: #ed8eef;
    }
  }
  &-subtitle {
    margin-top: 24px;
    width: 343px;
    margin-top: 24px;
    color: var(--Dark, #000656);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    z-index: 1;
  }
  &-btn {
    margin-top: 24px;
    font-size: 14px;
    color: var(--Background, #fcfffc);
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    border-radius: 23.451px;
    padding: 8.599px 18.761px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.817px;
    background: var(--Dark, #000656);
    cursor: pointer;
    transition: transform 0.2s; /* 添加过渡效果 */
    z-index: 1;
  }
  &-btn:hover {
    transform: scale(1.1);
  }
  &-img {
    width: 375px;
    height: 306px;
  }
  &-bottom {
    color: var(--Background, #fcfffc);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 40px 16px;
    background-color: #000656;
    b {
      color: #c7fff9;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;