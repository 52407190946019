@import '~@/themes/func.less';
@solution-prefix: ~'@{cor-prefix}-solution';
.@{solution-prefix} {
  width: 1248px;
  margin: 0 auto;
  margin-top: 150px;
  padding-bottom: 67px;
  &-title {
    color: var(--Dark, #000656);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    .highlight {
      font-weight: 600;
    }
  }
  &-container {
    margin-top: 32px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &-left {
      height: 280px;
      background: linear-gradient(281.55deg, #fffffd 3.89%, #ffffe3 136.79%, #feffc8 159.35%);
    }
    &-center {
      height: 384px;
      background: linear-gradient(358.73deg, #fffef9 -60.44%, #e0fffb 146.26%, #c4fff8 168.83%);
    }
    &-right {
      height: 474px;
      background: linear-gradient(77.66deg, #fcfffa 3.4%, #fee8fb 107.37%, #ffd8fb 125.49%);
    }
    &-tong {
      color: #000656;
      padding: 24px;
      width: 373px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 4px solid #000656;
      position: relative;
      &-img {
        width: 50px;
        height: 45px;
      }
      &-title {
        color: var(--Dark, #000656);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
        position: absolute;
        bottom: 154px;
      }
      &-subtitle {
        color: var(--Dark, #000656);
        font-family: sora;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        text-align: left;
        margin-bottom: 0;
        position: absolute;
        bottom: 24px;
      }
      .tips1 {
        height: 4px;
        width: 4px;
        background-color: #fcfffc;
        position: absolute;
        top: -4px;
        left: -4px;
      }
      .tips2 {
        height: 8px;
        width: 8px;
        background-color: #000656;
        position: absolute;
        top: -8px;
        right: -8px;
        div {
          height: 4px;
          width: 4px;
          background-color: #fcfffc;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .tips3 {
        height: 8px;
        width: 8px;
        position: absolute;
        bottom: -4px;
        left: -4px;
        background-color: #fcfffc;
        div {
          height: 4px;
          width: 4px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #000656;
        }
      }
      .tips1 {
        height: 4px;
        width: 4px;
        background-color: #fcfffc;
        position: absolute;
        bottom: -4px;
        right: -4px;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;