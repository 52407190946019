@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@chat-content-item-prefix: ~'@{cor-prefix}-chat-content-item';
.@{chat-content-item-prefix} {
  display: flex;
  column-gap: 16px;
  width: 100%;
  &:not(:first-of-type) {
    margin-top: 8px;
  }
  &.Response {
    justify-content: flex-end;
    align-items: flex-start;

    .ant-avatar {
      order: 2;
    }

    .@{chat-content-item-prefix} {
      &-content {
        order: 1;
        align-items: flex-end;

        .date-and-option {
          width: 100%;
          justify-content: space-between;
          &-left {
            order: 1;
          }
        }
      }

      &-timestamp {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &-right {
    padding: 18px 28px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #f8f9fc;
  }
  &-content {
    display: flex;
    flex-direction: column;
    row-gap: @space-2x;
    order: 2;
    overflow: auto;
    max-width: calc(100% - 116px);
  }

  &-text {
    margin-top: 5px;
    padding: 15px 0;
    max-width: 100%;
    color: #333;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &-subject {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .subject-1 {
        border-radius: 8px;
        background: linear-gradient(138deg, #000656 -20.61%, #99f 72.63%);
        width: 62px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
      }
      .subject-2 {
        color: #333;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
    }

    p:empty {
      display: none;
    }

    pre {
      white-space: pre-wrap;
      overflow-x: auto;
      overflow-y: hidden;
      word-wrap: break-word;
      margin-bottom: 0;
    }

    .ant-btn.ant-btn-link {
      padding: 0;
      color: #1082ff;
      float: right;
    }
  }

  &-html {
    transition: all ease-in-out 0.3s;
    word-wrap: break-word;

    &.overflow {
      position: relative;
      max-height: 6.5em;
      overflow: hidden;
    }
  }

  &-btns {
    display: flex;
    column-gap: @space-2x;
    align-items: center;

    .ant-btn {
      padding: 0;
      height: 26px;
      color: #1082ff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-timestamp {
    max-width: 100%;

    .date-and-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
      padding-bottom: 11px;
      border-bottom: 1px solid #1f2c4712;
      &-left {
        display: flex;
        flex-direction: column;
        font-family: Inter;
        font-size: 14px;
        line-height: 16px;
        .name {
          color: #000000;
          font-weight: 700;
        }

        .date {
          margin-top: 10px;
          color: rgba(51, 51, 51, 0.6);
        }
      }

      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .collapse,
        .expand {
          display: flex;
          align-items: center;
          padding: 0px;
          height: 16px;
          transition: all ease-in-out 0.3s;

          span + .anticon {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
  .sb-avatar__text {
    background: #d3d5edb2 !important;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;