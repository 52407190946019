@import '~@/themes/func.less';
@addCategory-prefix: ~'@{cor-prefix}-addCategory';
.@{addCategory-prefix} {
  &-edit{
    height: 30px;
    width: 30px;
  }
  &-btn {
    svg {
      width: 20px;
      height: 20px;
    }
    &.ant-btn-primary {
      border: none;
      padding: 8px 10px;
      border-radius: 8px;
      background: var(--Dark, #000656) !important;
      color: var(--red, #fff) !important;;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.52px;
    }
  }
  .comment-modal {
    .comment-input {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  
    .send-button {
      margin-top: 20px;
      width: 100%;
    }
  
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;