@import '~@/themes/func.less';
@user-search-prefix: ~'@{cor-prefix}-user-search';

.@{user-search-prefix} {
  .ant-input-affix-wrapper {
    width: 100%;
    background: none;
    border-radius: 8px;
    border: 2px solid var(--border, rgba(31, 44, 71, 0.07));
    height: 36px;
    padding: 10px;
    display: flex;
    align-items: center;
    border: 2px solid var(--border, rgba(31, 44, 71, 0.07));
    .ant-input-suffix {
      background: none;
      margin-right: 10px;
      svg {
        height: 16px;
        width: 16px;
      }
    }
    input.ant-input {
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      height: 16px;
      background: none;
    }
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;