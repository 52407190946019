@import '~@/themes/func.less';
@mibileSolution-prefix: ~'@{cor-prefix}-mibileSolution';
.@{mibileSolution-prefix} {
  width: 100%;
  padding: 40px 16px;
  &-title {
    color: var(--Dark, #000656);
    text-align: center;
    font-family: Sora;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    &-tong {
      color: #000656;
      padding: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border: 2px solid #000656;
      position: relative;
      &-img {
        width: 32px;
        height: 29px;
      }
      &-title {
        color: var(--Dark, #000656);
        font-family: Sora;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &-subtitle {
        margin-top: 20px;
        color: var(--Grey-Body-Text, #4c4f71);
        font-family: Sora;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.06px;
      }
      .tips1 {
        height: 2px;
        width: 2px;
        background-color: #fcfffc;
        position: absolute;
        top: -2px;
        left: -2px;
      }
      .tips2 {
        height: 4px;
        width: 4px;
        background-color: #000656;
        position: absolute;
        top: -4px;
        right: -4px;
        div {
          height: 2px;
          width: 2px;
          background-color: #fcfffc;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .tips3 {
        height: 4px;
        width: 4px;
        position: absolute;
        bottom: -2px;
        left: -2px;
        background-color: #fcfffc;
        div {
          height: 2px;
          width: 2px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #000656;
        }
      }
      .tips1 {
        height: 2px;
        width: 2px;
        background-color: #fcfffc;
        position: absolute;
        bottom: -2px;
        right: -2px;
      }
    }
    &-left {
      width: 240px;
      background: linear-gradient(281.55deg, #fffffd 3.89%, #ffffe3 136.79%, #feffc8 159.35%);
    }
    &-center {
      width: 306px;
      background: linear-gradient(358.73deg, #fffef9 -60.44%, #e0fffb 146.26%, #c4fff8 168.83%);
    }
    &-right {
      width: 338px;
      background: linear-gradient(77.66deg, #fcfffa 3.4%, #fee8fb 107.37%, #ffd8fb 125.49%);
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;