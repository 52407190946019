@import '~@/themes/func.less';
@delete-connection-button-prefix: ~'@{cor-prefix}-delete-connection-button';
.@{delete-connection-button-prefix} {
  &.ant-btn {
    width: auto;
    background: var(--border, #000656);
    padding: 12px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    &:hover {
      background: var(--border, #000656);
      color: #333;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;