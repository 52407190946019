@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@landing-prefix: ~'@{cor-prefix}-landing';

.@{landing-prefix} {
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fcfffc;
  margin: 0 auto;
  position: relative;
  font-family: sora;
  &-center {
    width: 1440px;
    margin: 0 auto;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;