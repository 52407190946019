@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@edit-template-prefix: ~'@{cor-prefix}-edit-template';

.@{edit-template-prefix} {
  &-modal {
    .ant-col {
      display: flex;
      flex-direction: column;
      row-gap: @space-4x;
    }

    .ant-form {
      display: flex;
      flex-direction: column;
      row-gap: @space-8x;

      textarea.ant-input {
        border-radius: @space-2x;
        padding: @space-3x;
        height: 230px;
      }

      .ant-btn.cor-save {
        align-self: center;
        margin: 0 auto;
      }
    }
  }

  &-prompt {
    &.ant-form-item {
      .ant-form-item-label {
        height: 34px;
        align-items: center;
        display: flex;

        label {
          width: 100%;

          &:after {
            display: none !important;
          }
        }

        .ant-btn-link {
          text-decoration: underline;
        }
      }
    }

    &-label {
      display: flex;
      align-items: center;
      column-gap: 24px;
      width: 100%;
      justify-content: space-between;

      span {
        display: flex;
        align-items: center;
        column-gap: 4px;
      }

      .ant-btn {
        padding: 4px 8px;
        border-radius: 4px;
        .font12;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;