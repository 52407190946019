@import '~@/themes/func.less';
@assignee-prefix: ~'@{cor-prefix}-query-header';

.@{assignee-prefix} {
  display: flex;
  flex-direction: column;
  row-gap: 24px;


  &-search,
  &-drawer-search {
    padding: 10px;
    height: 38px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(31, 44, 71, 0.07);
    color: rgba(51, 51, 51, 1);

    .ant-input-affix-wrapper-focused {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    .ant-input-affix-wrapper {
      width: 100%;

      background: none;
      border: none;

      .ant-input-suffix {
        background: none;
      }

      .ant-input-prefix {
        margin-right: 10px;
      }

      input {
        background: none !important;
        padding: 0 !important;
        border: none !important;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }
    }
  }

  &-search {
    width: 456px;
  }

  &-drawer-search {
    margin: 12px;
  }

  &-assignees {
    display: flex;
    align-items: center;
    column-gap: 15px;

    .assignees-text {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      color: rgba(51, 51, 51, 0.6);
    }

    .ant-btn.ant-btn-sm {
      padding: 8px 10px;
      height: 34px;
      gap: 7px;
      border-radius: 20px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: rgba(51, 51, 51, 0.6);

    }

    .ant-btn.ant-btn-primary {
      color: #fff;
    }

    .ant-btn>.anticon+span,
    .ant-btn>span+.anticon {
      margin-left: 0;
    }
  }

  &-drawer {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    &-team {
      margin-top: 12px;
      border-top: 1px solid #f0f0f0;
      padding: 12px 20px 20px;
      overflow: auto;
      flex: 1;
    }

    &-item {
      display: flex;
      flex: 1;
      align-items: flex-start;
      align-items: center;
      padding: 12px;
      margin: 12px 0;
      border-radius: 0.8rem;
      cursor: pointer;

      &:hover,
      &.selected {
        background: linear-gradient(90deg, rgba(0, 128, 255, 0.5) 0, rgba(153, 153, 255, 0.5) 100%);

        .cor-query-header-drawer-item-check {
          color: #000656;
        }
      }

      // &-avatar {}

      &-text {
        flex: 1;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 10px;
      }

      &-check {
        color: rgba(51, 51, 51, 0.6);

        svg {
          font-size: 20px;
        }
      }
    }

    &-all {
      border-top: 1px solid #f0f0f0;
      padding: 20px;
    }
  }

  &-status {
    display: flex;
    column-gap: 14px;

    .ant-btn {
      min-width: 68px;

      &.ant-btn-text {
        color: rgba(51, 51, 51, 0.6);
      }

      &.ant-btn-primary,
      &.ant-btn-text:hover {
        color: var(--Dark, #000656);
        background: linear-gradient(247.43deg, #E8EAFF 26.95%, #F6D2F9 144.89%);

      }

      &.ant-btn-text,
      &.ant-btn-primary {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;
        padding: 8px 10px;
        height: initial;
        border-radius: 20px;
        border: 0;
      }
    }


    .ant-radio-button-wrapper {
      text-transform: capitalize;
    }
  }

  &-multiple {
    justify-content: space-between;
    width: 132px;
    padding: 10px 17px 10px 12px;
    border-radius: 8px;
    border: 1px solid rgba(31, 44, 71, 0.07);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.6);

    &.ant-popover-open {
      svg {
        transform: rotate(-180deg);

      }
    }

    &-popover {
      // border: 1px solid rgba(31, 44, 71, 0.07);
      padding-top: 0;
      // border-radius: 8px;
      // overflow: hidden;
      // position: relative;


      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-content {
        border: 1px solid rgba(31, 44, 71, 0.07);
        padding-top: 0;
        border-radius: 8px;
        overflow: hidden;
        transform: translateY(-4px);

      }

      .ant-checkbox-inner {
        background: #d9d9d9;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background: #000656;
      }

      .ant-popover-inner-content {
        padding: 16px 8px;
        min-width: 130px;

      }

      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        row-gap: 7px;
      }

      .ant-checkbox-group-item {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    // &:after {

    //   content: '';
    //   display: inline-block;
    //   width: 5px;
    //   height: 5px;
    //   border-width: 0 0 1px 1px;
    //   border-style: solid;
    //   transform: rotate(-45deg);
    //   position: relative;
    //   // top: -0.1rem;
    // }
  }


  &-bottom {
    display: flex;
    column-gap: 15px;
    align-items: center;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;