@import '~@/themes/func.less';
@chat-reply-box-prefix: ~'@{cor-prefix}-chat-reply-meta-box';

.@{chat-reply-box-prefix} {
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  &-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .ant-input {
      border: none;
      background: #f8f9fc;
      font-size: 12px;
      color: #333;

      &:focus {
        border: none;
        border-bottom: none;
        box-shadow: none;
      }
    }

    .ant-input:hover {
      border: none;
    }

    .textarea {
      margin-bottom: 24px;
      width: 100%;
      font-size: 16px;
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      background: #f8f9fc;

      &:focus {
        border: 1px solid #d9d9d9;
        border-bottom: none;
        box-shadow: none;
      }

      .top {
        min-height: 80px;
        max-height: 120px;
        overflow-y: auto;
      }

      &.email-content {
        border-radius: 8px 8px 0 0;
      }

      .ant-input {
        padding: 0;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btns {
        display: flex;
        column-gap: 12px;
        align-items: center;
      }
    }

    .normal-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      color: #ffffff;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;

      .ant-btn-primary {
        width: 100%;
        height: 40px;
        border: none;
        padding: 0;
        color: #ffffff;
        font-size: 12px;

        .anticon-send {
          height: 14px;
          width: 14px;
        }
      }
    }

    .ant-btn,
    .ant-btn-primary {
      background-color: #66f !important;
      color: #fff;
      border-color: #66f;
    }

    .generate {
      min-width: 168px;
      height: 32px;
      border-radius: 8px;
      background: #000656;
    }

    .bluesend {
      min-width: 85px;
      height: 32px;
      border-radius: 8px;
      background: #66f;
    }

    .tools {
      border: 1px solid #d9d9d9;
      border-top: none;
      background: #f8f9fc;
      border-radius: 0 0 12px 12px;
      padding: 0 12px 12px;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;