@import '~@/themes/func.less';
@session-item-prefix: ~'@{cor-prefix}-demo-session-item';
.@{session-item-prefix} {
  padding: 12px 16px;
  // display: flex;
  // align-items: center;
  position: relative;
  cursor: pointer;
  row-gap: 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  &:hover {
    background: linear-gradient(90deg, rgba(153, 153, 255, 0.05) 0%, rgba(0, 128, 255, 0.05) 100%);
  }
  &::before {
    content: '';
    height: 100%;
    width: 4px;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #000656;
    transition: all 0.2s linear;
    transform: scale(0);
  }
  &.selected {
    &::before {
      transform: scale(1);
    }
    border-bottom: 1px solid var(--border, #000656);
    background: var(--hover, linear-gradient(90deg, rgba(153, 153, 255, 0.05) 0%, rgba(0, 128, 255, 0.05) 100%));
  }
  &.disabled {
    cursor: not-allowed;
  }
  &-date {
    font-weight: 400;
    font-size: 14px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;