@import '~@/themes/func.less';
@chat-reply-box-prefix: ~'@{cor-prefix}-chat-reply-mail-box';

.@{chat-reply-box-prefix} {
  border-top: 1px solid var(--border, rgba(31, 44, 71, 0.07));
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .ant-input {
      border: none;
      background: #fff;
      font-size: 12px;
      color: #333;

      &:focus {
        border: none;
        border-bottom: none;
        box-shadow: none;
      }
    }

    .ant-input:hover {
      border: none;
    }

    .textarea {
      width: 100%;
      font-size: 16px;
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid #e7e7ec;
      // background: #f8f9fc;
      &:focus {
        border: 1px solid #d9d9d9;
        border-bottom: none;
        box-shadow: none;
      }
      .top {
        // min-height: 80px;
        // max-height: 120px;
        overflow-y: auto;
      }

      &.email-content {
        border-radius: 8px 8px 0 0;
      }
      .ant-input {
        padding: 0;
      }
    }

    .email-signature {
      border-radius: 0;
      border-top: none;
    }
    .header {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .btns {
        display: flex;
        column-gap: 12px;
        align-items: center;
        .ant-btn,
        .ant-btn-primary {
          background-color: #fff !important;
          color: #333333; /* 设置文本颜色 */
          border: 1px solid #e7e7ec !important; /* 设置边框颜色 */
        }
        .ant-btn.ant-btn-primary svg path {
          fill: #000;
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: flex-end;
    }

    .normal-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      color: #ffffff;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      .ant-btn-primary {
        width: 100%;
        height: 40px;
        border: none;
        padding: 0;
        color: #ffffff;
        font-size: 12px;

        .anticon-send {
          height: 14px;
          width: 14px;
        }
      }
    }

    .generate {
      min-width: 168px;
      height: 32px;
      border-radius: 8px;
      background: #000656;
    }

    .bluesend {
      min-width: 85px;
      height: 36px;
      border-radius: 8px;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;