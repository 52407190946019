.ant-form-item {
  margin: 0 !important;
  flex: 1;
  .ant-row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-items: stretch;
    row-gap: @space;
    .ant-form-item-label {
      text-align: left;
      label {
        .font14;

        height: 16px;
        &::after {
          display: none;
        }
        &::before {
          .font12;
        }
      }
    }
  }
}
.ant-form {
  display: flex;
  flex-direction: column;
  row-gap: @space-4x;
}
