@import '~@/themes/func.less';

.cor-list {
  &-item {
    position: relative;
    display: flex;
    padding: 24px;
    padding-bottom: 32px;
    padding-left: 40px;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    box-sizing: border-box;
    position: relative;
    background: rgba(0, 51, 102, 0.05);

    &.selected {
      background: #fff;
      .cor-list {
        &-item-left {
          display: block;
        }
      }
    }

    &-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid transparent;
      color: #333;

      &-left {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          color: #000656;
        }
      }

      &-right {
        color: rgba(51, 51, 51, 0.6);
        font-family: Inter;
        font-size: 14px;
        line-height: normal;
        font-style: normal;
        font-weight: 400;
      }
    }

    &-subject {
      margin-top: 8px;
      color: #333;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      font-weight: 400;
      // white-space: nowrap;  don't need if wrapping two lines
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limit to 2 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-tags {
      position: absolute;
      bottom: 0px;
      height: 32px;
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    &-left {
      display: none;
      background: var(--Pinkish-purple, #febeff);
      width: 9px;
      height: 9px;
      border-radius: 9px;
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translate(-50%, -50%);
    }

    &-right {
      flex: 1;
      width: calc(100% - 100px);
      min-width: calc(100% - 100px);
      display: flex;
      flex-direction: column;
    }

    &-archive {
      width: 24px;
      height: 24px;
      padding: 0;
      border: none;
      background: none;
      &:hover {
        border: none;
        background: none;
      }
      svg {
        display: none;
      }
    }

    &:hover {
      background: linear-gradient(90deg, rgba(153, 153, 255, 0.05) 0%, rgba(0, 128, 255, 0.05) 100%);

      .cor-list-item-archive {
        svg {
          display: block;
        }
      }
    }

    &.unread {
      background: #fff;

      .cor-list {
        &-item-name {
          font-size: 18px;
          line-height: normal;
          font-weight: 700;

          .cor-formated-date {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;