@import '~@/themes/func.less';
@cor-uploader-prefix: ~'@{cor-prefix}-input-uploader';
.@{cor-uploader-prefix} {
  &-pre {
    margin-top: 8px;
    max-height: 300px;
    overflow-y: auto;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    border: 1px solid @border-color;
    padding: 16px;
    border-radius: 8px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;