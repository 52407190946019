@import '~@/themes/func.less';
@import '~@/themes/variable.less';
@email-categorization-prefix: ~'@{cor-prefix}-email-categorization';

.@{email-categorization-prefix} {
  margin: auto;
  padding:0 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  &-header,
  &-subject,
  &-content,
  &-category {
    margin-bottom: 24px;
  }
  &-category{
    overflow: auto;
    max-height: 200px;
    width: 100%;
    .radio-group {
        padding-left: 100px;
    
    }
    .radio-group .ant-radio {
        margin-top: 20px;
        font-size: 16px; 
      }
      
      .radio-group .ant-radio-inner {
        width: 20px; 
        height: 20px; 
        border-width: 2px;
      }
      
  }
  &-content {
    max-height: 280px;
    overflow: auto;
    p {
      margin: 0;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 30px;
    left: 100px;
    width: 100%;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;