@import '~@/themes/func.less';
@banner-prefix: ~'@{cor-prefix}-banner';

.@{banner-prefix} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  position: relative;
  &-title {
    color: var(--Dark, #000656);
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 65px;
    z-index: 1;
    .highlight {
      font-weight: 600;
      color: #ed8eef;
    }
  }
  &-subtitle {
    margin-top: 20px;
    color: var(--Dark, #000656);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    z-index: 1;
  }
  &-btn {
    margin-top: 20px;
    display: flex;
    height: 44px;
    padding: 11px 24px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    gap: 10.471px;
    color: #000656;
    font-family: Sora;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: var(--Background, #fcfffc);
    text-transform: capitalize;
    background: var(--Dark, #000656);
    cursor: pointer;
    transition: transform 0.2s; /* 添加过渡效果 */
    z-index: 1;
  }
  &-btn:hover {
    transform: scale(1.1);
  }
  &-img {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 740px;
  }
  &-bottom {
    margin-top: 400px;
    color: var(--Background, #fcfffc);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 35px */
    letter-spacing: 0.14px;
    padding: 80px 96px;
    background-color: #000656;
    width: 100%;
    &-container {
      width: 954px;
      text-align: center;
      margin: 0 auto;
    }
    .tips {
      color: #bcfff8;
      font-style: italic;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;