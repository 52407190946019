@import '~@/themes/func.less';
@chat-reply-box-prefix: ~'@{cor-prefix}-chat-reply-box';
.@{chat-reply-box-prefix} {
  overflow: hidden;
  display: flex;
  column-gap: 6px;
  align-items: center;
  &-textarea {
    height: 112px;
    border-radius: 8px;
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    border: none;
    padding: 12px;
    flex: 1;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
  .ant-btn {
    width: 35px;
    padding: 0;
    height: 35px;
    padding: 0;
    border-radius: 100%;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;