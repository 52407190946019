@color-primary: #3f9c94;
@bg-primary: #f1f1f1;
@text-primary: '#1C1B1B';
.cor-link-preview {
  display: flex;
  column-gap: 4px;
  flex-direction: column;
  row-gap: 8px;
  &-link {
    text-decoration: underline;
    &:hover {
      color: @color-primary;
    }
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-width: 1px;
    &-left {
      display: flex;
      align-items: center;
      column-gap: 4px;
      white-space: wrap;
    }
    &-variants {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
    &.mobile {
      padding: 4px;
      .cor-link-preview-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  &-title {
    font-family: Sora;
    display: flex;
    column-gap: 4px;
    align-items: center;
    color: #333;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    text-decoration: underline;
  }
  &-desc {
    width: 100%;
    .wmde-markdown {
      font-family: sora, sans-serif;
    }
  }
  &-images {
    display: grid;
    row-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    column-gap: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    &.mobile {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
    }
  }
  &-modal.ant-modal {
    font-family: Sora;
    .ant-modal-body {
      padding: 24px;
      border-width: 2px;
      max-height: calc(var(--doc-height) * 0.7);
      overflow: auto;
      position: relative;
      overscroll-behavior: contain;
      .ant-carousel {
        width: 100%;
        .slick-list {
          width: 100%;
        }
        .slick-dots {
          li button {
            background: @color-primary;
          }
        }
      }
      .wmde-markdown {
        color: unset;
        background: unset;
      }
    }
  }
  &.mobile {
    .ant-carousel {
      min-height: 150px;
      .slick-list {
        min-height: 150px;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;