@import '~@/themes/func.less';
@chat-header-prefix: ~'@{cor-prefix}-mailbox-detail-header';

.@{chat-header-prefix} {
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  .sb-avatar__text {
    background: #d3d5edb2 !important;
  }
  &-left {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  .ant-btn.ant-btn-text {
    padding: 0;
    color: #1082ff;
    height: 18px;
    font-size: 16px;
    font-weight: 400;
  }

  &-right {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      color: #333;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }

    .email {
      margin-top: 5px;
      color: rgba(51, 51, 51, 0.6);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;