@import '~@/themes/func.less';
@documentsCard-prefix: ~'@{cor-prefix}-documentsCard';
.@{documentsCard-prefix} {
  height: 100%;
  overflow: auto;
  width: 710px;
  padding: 0 20px 20px 20px;
  background: var(--White, #ffffff);
  height: calc(100vh - 150px);
  // border: 2px solid rgba(31, 44, 71, 0.07);
  // border-radius: 12px;
  &-head {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-allbtn {
      &.ant-btn-primary {
        padding: 8px 10px;
        border-radius: 8px;
        background: var(--Button, #ffffff) !important;
        color: var(--red, #f66);
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        border: 1px solid #e7e7ec;
      }
    }
  }
  &-center {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 323px);
    gap: 18px;
    &-item {
      box-shadow: 0px 2px 5px 0px #464b9208;
      border: 1px solid #e7e7ec;
      width: 323px;
      height: 250px;
      padding: 10px;
      border-radius: 5px;
      transition: background-color 0.3s ease, transform 0.3s ease;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 15px;
      &:hover {
        background-color: #f0f0f0;
        transform: scale(1.05);
      }

      &-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        color: #000;
      }

      &-content {
        color: #333;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        min-height: 110px;
      }
      &-tag {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        color: #33333399;
        display: flex;
        gap: 20px;
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;