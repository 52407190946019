@import '~@/themes/func.less';
@inbox-meta-prefix: ~'@{cor-prefix}-inbox-meta';

.@{inbox-meta-prefix} {
  flex: 1;
  display: flex;
  height: calc(100% - 156px);
  align-items: stretch;

  &-left {
    border-right: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    width: 352px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin: 0 auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    overflow: hidden;
    &-resizer {
      width: 6px;
      height: 100%;
      background-color: #ccc;
      cursor: ew-resize;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &-resizer.visible {
      opacity: 1;
    }
    &.open {
      transform: translateX(0);
    }
    &.closed {
      width: 0px;
      overflow: hidden;
      transform: translateX(-100%);
    }
  }
  &-right {
    flex: 1;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;