@import '~@/themes/func.less';
@delete-ticket-prefix: ~'@{cor-prefix}-delete-ticket';

.@{delete-ticket-prefix} {
  &-button {
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    cursor: pointer;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
}
.editing-confirm-modal {
  .ant-modal-content {
    .ant-modal-body{
      padding-top: 20px !important;
    }
    .ant-modal-footer {
      border-top: none !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;