@import '~@/themes/func.less';
@documentsDetail-prefix: ~'@{cor-prefix}-documentsDetail';
.@{documentsDetail-prefix} {
  height: calc(100vh - 150px);
  overflow: auto;
  width: 710px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  background: var(--White, #ffffff);
  padding-left: 20px;
  // border: 2px solid rgba(31, 44, 71, 0.07);
  // border-radius: 12px;
  &-head {
    display: flex;
    height: 51px;
    padding: 14px 30px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    background: #f5f5f5f5;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #33333399;
    // border-radius: 12px 12px 0 0;
  }
  &-center {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 30px 22px 30px;
    &-message {
      flex: 1;
      overflow: auto;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;

      .w-md-editor-content {
        height: 240px !important;
        .w-md-editor-input {
          width: 100%;
        }
        .wmde-markdown {
          display: none;
        }
      }
    }
    &-xian {
      height: 2px;
      width: 100%;
      background-color: rgba(31, 44, 71, 0.07);
    }
    &-title {
      font-family: Inter;
      font-size: 24px;
      font-weight: 500;
      line-height: 29.05px;
      text-align: left;
      color: #333333;
    }
    &-title2 {
      display: flex;
      align-items: center;
      .ant-input {
        margin-left: 10px;
        width: 400px;
      }
    }
    &-tags {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;

      &-left {
        color: rgba(51, 51, 51, 0.6);
      }
      &-right {
        display: flex;
        gap: 12px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  &-bottom {
    width: 100%;
    padding: 16px 20px;
    border-top: 2px solid rgba(31, 44, 71, 0.07);
    border-radius: 0 0 12px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
    &-del {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 25px;
      height: 26px;
      svg path {
        fill: #33333399;
      }
    }
    &-date {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #33333399;
    }
    &-edit {
      &.ant-btn {
        border: 2px solid rgba(31, 44, 71, 0.07) !important;
        padding: 8px 10px;
        border-radius: 8px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        color: var(--p1, #000656);
        svg path {
          fill: var(--p-1, #000656);
        }
      }
    }
    &-edit2 {
      &.ant-btn {
        border-radius: 0;
        border: none !important;
        border-bottom: 1px solid rgba(51, 51, 51, 0.6) !important;
        padding: 0 10px;
        padding-bottom: 0px !important;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        color: rgba(51, 51, 51, 0.6);
      }
    }
    &-edit3 {
      &.ant-btn {
        background: #000656;
        padding: 8px 10px;
        border-radius: 8px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        color: var(--p1, #fff);
      }
    }
  }
  .date-time-picker-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .date-time-picker-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .date-time-picker-item label {
    margin-right: 8px;
    width: 100px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: var(--text-2, rgba(51, 51, 51, 0.6));
  }

  .ant-picker {
    width: 168px;
    border-radius: 6px !important;
  }
  .checkbox-item {
    padding-left: 110px;
    margin-top: 10px;
  }
}
.cor-documentsDetail-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-btn {
    display: flex;
    gap: 20px;
  }
  &-title {
    width: 224px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;