@import '~@/themes/func.less';
@playground-reply-box-prefix: ~'@{cor-prefix}-playground-reply-box';
.@{playground-reply-box-prefix} {
  border-top: 1px solid rgba(31, 44, 71, 0.07);
  display: flex;
  flex-direction: column;

  &-input-container {
    width: 100%;
    .ant-input {
      border: none;
      background: #f8f9fc;
      font-size: 18px;
      line-height: 24px;
      color: #333;

      &:focus {
        border: none;
        border-bottom: none;
        box-shadow: none;
      }
    }

    .ant-input:hover {
      border: none;
    }

    .textarea {
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      padding: 16px 24px;
      border-radius: 8px;
      border: 1px solid rgba(31, 44, 71, 0.07);
      background: #f8f9fc;
      display: flex;
      align-items: center;
      column-gap: 24px;
      textarea {
        resize: none;
        display: flex;
        align-items: center;
        min-height: unset;
        font-size: 18px;
        line-height: 24px;
      }
      &:focus {
        border: 1px solid #d9d9d9;
        border-bottom: none;
        box-shadow: none;
      }

      .ant-input {
        padding: 0;
      }
    }
    .ant-btn {
      background-color: none !important;
      border-color: none;
      border: none;
      &:disabled {
        svg {
          path {
            fill: #e3efff;
          }
        }
      }
      svg {
        width: 24px;
        height: 24px;
        transform: rotate(-45deg);
        path {
          fill: #66f;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;