@import '~@/themes/func.less';
@analysis-prefix: ~'@{cor-prefix}-analysis';
.@{analysis-prefix} {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &-tabs {
    .ant-tabs {
      width: 100%;
      .ant-tabs-nav {
        margin: 0;
      }
      .ant-tabs-nav-wrap {
        padding-left: 32px;
      }
      &-tab {
        padding: 16px;
        &-btn {
          display: flex;
          column-gap: @space;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  &-container {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;