@import '~@/themes/func.less';
@chat-content-prefix: ~'@{cor-prefix}-chat-content';
.@{chat-content-prefix} {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 1;
  overflow-y: auto;
  padding: 28px 0;
  &-wrap {
    overflow: auto;
    height: 100%;
    padding: 0 28px;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f8f9fc;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #236fff;
      border-radius: 5px;
    }
  }
  .ant-btn.ant-btn-text {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }
  .ant-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::-webkit-scrollbar-thumb {
    background: #000656;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;