@import '~@/themes/func.less';
@features-prefix: ~'@{cor-prefix}-features';
.@{features-prefix} {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    width: 100%;
  }
  &-bottom {
    margin-top: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    background-color: #000656;
    position: relative;
    &-dian1 {
      width: 17px;
      height: 17px;
      background-color: #c7fff9;
      position: absolute;
      top: 159px;
      left: 100px;
    }
    &-dian2 {
      width: 24px;
      height: 24px;
      background-color: #f6ee36;
      position: absolute;
      top: 135px;
      right: 109px;
    }
    &-dian3 {
      width: 8px;
      height: 8px;
      background-color: #f6ee36;
      position: absolute;
      top: 273px;
      left: 134px;
    }
    &-dian4 {
      width: 12px;
      height: 12px;
      background-color: #c7fff9;
      position: absolute;
      top: 232px;
      right: 154px;
    }
    &-1 {
      color: var(--Purple, #f1f3f9);
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      b {
        font-weight: 600;
      }
    }
    &-2 {
      margin-top: 24px;
      padding: 12px 24px;
      background: #fff3fa;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: var(--Dark, #000656);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
    &-3 {
      width: 1000px;
      margin-top: 32px;
      color: var(--Purple, #f1f3f9);
      text-align: center;
      font-family: Sora;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 22.5px */
      letter-spacing: 0.09px;
    }
  }
  &-container {
    margin-top: 256px;
    width: 1244px;
    height: 553px;
    flex-shrink: 0;
    // border: 5px solid #000656;
    padding: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    column-gap: 24px;
    &:nth-of-type(1) {
      margin-top: 170px;
    }
    &-head {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      border: 5px solid #000656;
      border-bottom: none;
      gap: 10px;
      position: relative;
      &-tips1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        text-align: left;
      }
      &-tips2 {
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        b {
          font-weight: 600;
        }
      }
      &-left {
        height: 6px;
        width: 6px;
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: 2;
        background-color: #fff;
      }
      &-right {
        height: 10px;
        width: 10px;
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 2;
        background-color: #fff;

        div {
          height: 5px;
          width: 5px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #000656;
        }
      }
    }
    &-head1 {
      width: 364px;
      background: linear-gradient(90deg, #ffffe3; #fffff3);
      position: absolute;
      top: -60px;
      left: 40px;
    }
    &-head2 {
      width: 374px;
      background: linear-gradient(90deg, #E0FFFB; #e0fffb);
      position: absolute;
      top: -60px;
      left: 437px;
    }
    &-head3 {
      width: 364px;
      background: linear-gradient(90deg, #f6eef5; #fee8fb);
      position: absolute;
      top: -60px;
      right: 40px;
    }
    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      &-1 {
        color: var(--Dark, #000656);
        leading-trim: both;
        text-edge: cap;
        font-size: 32px;
        font-weight: 600;
        line-height: normal;
      }
      &-2 {
        color: var(--Body-Text, #22265d);
        leading-trim: both;
        text-edge: cap;
        font-family: Sora;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.08px;
      }
      &-3 {
        display: flex;
        width: 214px;
        padding: 11px 24px;
        justify-content: center;
        align-items: center;
        color: var(--White, #fff);
        text-align: center;
        font-family: Sora;
        font-size: 16px;
        border-radius: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.08px;
        background-color: #000656;
        cursor: pointer;
        transition: transform 0.2s; /* 添加过渡效果 */
      }
      &-3:hover {
        transform: scale(1.1);
      }
    }
    &-right {
      width: 530px;
      height: 400px;
    }
  }
  &-cantainer:nth-of-type(1) {
    border: 5px solid #000656;
  }
  &-type1 {
    background: linear-gradient(281.55deg, #fffffd 3.89%, #ffffe3 136.79%, #feffc8 159.35%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  &-type2 {
    background: linear-gradient(358.73deg, #fffef9 -60.44%, #e0fffb 146.26%, #c4fff8 168.83%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  &-type3 {
    background: linear-gradient(77.66deg, #fcfffa 3.4%, #fee8fb 107.37%, #ffd8fb 125.49%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;