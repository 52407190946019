@import '~@/themes/func.less';
@send-mail-modal-prefix: ~'@{cor-prefix}-send-mail-modal';
.@{send-mail-modal-prefix} {
  &-container {
    overscroll-behavior: contain;
  }
  &-btn {
    &.ant-btn {
      display: flex;
      border-radius: 8px;
      width: fit-content;
      padding: 8px 10px;
      &:hover {
        svg {
          path {
            fill: #fff;
            fill-opacity: 1;
          }
        }
      }
      &.ant-btn-text {
        height: 24px;
        width: 24px;
        padding: 0;
      }
      &.ant-btn-primary {
        svg {
          path {
            fill: #fff;
            fill-opacity: 1;
          }
        }
        &.mobile:not(.ant-btn-sm) {
          height: 48px;
          width: 48px;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &.ant-modal {
    width: calc(var(--doc-width) * 0.6) !important;
    .ant-modal-close {
      top: 12px;
    }
    .ant-modal-body {
      padding: 32px;
      max-height: calc(var(--doc-height) - 48px);
      overscroll-behavior: contain;
      overflow: auto;
    }
  }
  &.mobile.ant-modal {
    width: calc(var(--doc-width) - 24px) !important;
    .ant-modal-body {
      padding: 12px;
      width: 100%;
      max-height: calc(var(--doc-height) * 0.9);
      overflow-y: scroll;
    }
  }
  &-form {
    &-btns {
      display: flex;
      column-gap: 8px;
      justify-content: flex-end;
    }
    &-content {
      border-radius: 4px;
      border: 1px solid rgba(31, 44, 71, 0.07);
      textarea {
        outline: none;
        &:hover,
        &:focus,
        &.ant-input-status-error {
          border-color: #000656 !important;
          box-shadow: none !important;
        }
      }
      &.focused {
        border: 1px solid rgba(31, 44, 71, 0.7);
        border-radius: 2px;
        box-shadow: 0 0 0 2px rgba(31, 44, 71, 0.07);
      }

      // 移除输入框自身的焦点样式
      textarea.ant-input {
        &:hover,
        &:focus {
          border-color: #d9d9d9 !important;
          box-shadow: none !important;
        }
      }
      &-content.ant-input {
        border: none;
        border-radius: 4px 4px 0 0;
        padding-bottom: 0;
        background: #f8f9fc !important;
      }
      &-signature.ant-input {
        border: none;
        background: #f8f9fc !important;
        border-radius: 0 0 4px 4px;
      }
      &-ai {
        &-container {
          width: 100%;
          position: relative;
          padding: 16px;
          border: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          margin-bottom: 16px;
          border-radius: 4px;
          &-close {
            position: absolute;
            top: 4px;
            right: 4px;
            cursor: pointer;
          }
          &-input {
            display: flex;
            gap: 16px;
            position: relative;
            align-items: stretch;
            .ant-btn {
              height: unset;
            }
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;