@import '~@/themes/func.less';
@customer-drawer-prefix: ~'@{cor-prefix}-customer-drawer';
.@{customer-drawer-prefix} {
  &.ant-drawer {
    .ant-drawer-content-wrapper {
      width: 600px !important;
    }
    .ant-drawer-wrapper-body {
      display: flex;
      flex-direction: column;
      .ant-drawer-body {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
  &.mobile.ant-drawer {
    .ant-drawer-content-wrapper {
      width: calc(100svw - 24px) !important;
    }
  }
  &-title {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    flex: 1;
    overflow-y: auto;
    &-title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      padding-bottom: 8px;
    }
    &-orders {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      &-item {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        &-desc {
          display: flex;
          column-gap: 16px;
          align-items: centers;
          border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
          padding-bottom: 8px;
          dl {
            margin: 0;
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            dd,
            dt {
              margin: 0;
            }
          }
        }
        &-lines {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          &-item {
            display: flex;
            column-gap: 4px;
            padding: 8px 0;
            border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
            align-items: center;
            span {
              flex: 1;
              text-align: center;
              &:first-of-type {
                text-align: left;
              }
              &:last-of-type {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;