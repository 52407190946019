@import '~@/themes/func.less';
@post-list-prefix: ~'@{cor-prefix}-post-list';

.@{post-list-prefix} {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  .ant-empty {
    margin-top: 48px;
  }
  &-wrap {
    overflow-y: auto;
    flex: 1;

    * {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f8f9fc;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #236fff;
        border-radius: 5px;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f8f9fc;
    }

    &::-webkit-scrollbar-thumb {
      background: #236fff;
      border-radius: 4px;
    }
    &.mobile {
      .@{post-list-prefix} {
        row-gap: 12px;
      }
    }
  }
  &-skeleton {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    &-item {
      padding: 12px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      &-btns {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 16px;
    border-radius: 8px;
    border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
    background: rgba(0, 51, 102, 0.05);
    &::before {
      content: '';
      height: 100%;
      width: 4px;
      position: absolute;
      left: 0px;
      top: 0px;
      background-color: #000656;
      transition: all 0.2s linear;
      transform: scale(0);
    }
    &.current {
      &::before {
        transform: scale(1);
      }
      border-bottom: 1px solid var(--border, rgba(31, 44, 71, 0.07));
      background: var(--hover, linear-gradient(90deg, rgba(153, 153, 255, 0.05) 0%, rgba(0, 128, 255, 0.05) 100%));
    }
    &.unreplied {
      background: #fff;
    }
    &.mobile {
      padding: 8px 12px;
    }
    &-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: #333;

      &-right {
        font-family: Inter;
        font-size: 14px;
        line-height: normal;
        font-style: normal;
        font-weight: 700;
        flex-direction: column;
        row-gap: 8px;
        .cor-formated-date {
          font-weight: 400;
        }
      }
    }

    &-content {
      color: #333;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      font-weight: 400;
    }

    &-tags {
      display: flex;
      column-gap: 8px;
      align-items: center;
      justify-content: flex-end;
      svg {
        path {
          fill: #236fff;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    &-comments {
      padding-left: 24px;
    }

    &-modal {
      .ant-modal-body {
        flex: 1;
        position: relative;
        max-height: calc(var(--doc-height) * 0.9);
        display: flex;
        flex-direction: column;
        padding-top: 32px;
        .cor-comment-list-wrap {
          .cor-comment-list {
            max-height: unset;
          }
        }
      }
      &.mobile {
        .ant-modal-body {
          padding: 0;
          padding-top: 32px;
        }
      }
    }
  }
}

@primary-color: #000656;@layout-header-height: 32px;@menu-bg: transparent;